@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none !important;
}

button {
  line-height: 1.538;
  padding: 7px 20px;
  border-radius: 4px;
  transition: none;
  min-height: 38px;
  display: inline-block;
  font-weight: 400;
  color: #8f9cc0;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.175rem 2.25rem 0.175rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4d577d;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e8e8f7;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 38px;
  border-radius: 5px;
}

body,
html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  background: #eaedf7;
  color: #4f4f4f;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
}
*:focus {
  outline: none !important;
}

*:focus {
  outline: none !important;
}

.mainwrapper {
  height: 100%;
  min-height: 100%;
  display: flex;
}

.menu {
  background: #333;
  overflow: hidden;
  width: 300px;
  transition: 0.3s;
}

.menu nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu nav ul li {
  width: 300px;
  padding: 25px 0;
  transition: 0.1s;
}

.menu nav ul li:hover:not(:first-child) {
  background: rgb(28, 106, 250);
  /* padding: 20px 50px 20px 10px; */
  /* border-radius: 100px; */
  cursor: pointer;
}

.menu nav ul li:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.menu nav ul li:first-child button img {
  margin-left: 7px;
  margin-right: 30px;
}

.menu nav ul li a,
.menu nav ul li button {
  color: #fff;
  text-decoration: none;
  padding: 0 20px;
  background: none;
  border: none;
  outline: 0;
}

.menu nav ul li a img,
.menu nav ul li button img {
  margin-right: 20px;
}

.menu #showMenu {
  display: none;
  font-size: 14px;
  padding-left: 26px;
}

.minMenu {
  width: 67px;
}

.content {
  width: 100%;
}

.smaller {
  width: calc(100% - 190px);
  margin-left: 180px;
  transition: 0.3s ease;
}

.no-wrap {
  flex: 0 0 auto;
}

.panels {
  padding: 15px 20px;
  width: auto;
  transition: 0.3s ease;
}

.panels__kanban {
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100%;
}

.painel {
  margin-bottom: 15px;
  position: relative;
}

.painel-content {
  background: #fff;
  border-radius: 11px;
  min-height: 100%;
  padding: 10px;
}

.painel .painel-title {
  padding: 8px 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(28, 39, 60);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1px;
}

.painel .painel-title.center {
  display: block !important;
  text-align: center;
}

.painel .painel-subtitle {
  border-bottom: 0.5px solid #d8d8d8;
  padding: 15px 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.painel .painel-subtitle strong {
  color: #444444;
  margin-right: 8px;
}

.painel .painel-title .panel-search {
  border: none;
  background: #fff url(/static/media/search-icon.ec2399bd.png) 3% center no-repeat;
  padding-left: 40px;
  width: 300px;
}

.painel .painel-title a {
  background: #6259ca;
  color: #fff !important;
  border-radius: 10px;
  padding: 10px;
}

.painel .painel-title a[role='button'] {
  padding: 6px 16px;
  border-radius: 4px;
  color: #fff;
}

.painel .painel-title a:hover {
  color: #574cca;
  background: #fff !important;
  text-decoration: none;
  color: #999 !important;
}

.painel .painel-title a[role='button']:hover {
  padding: 6px 16px;
  border-radius: 4px;
  background: #534ca4 !important;
  color: white !important;
  border: none;
}

.painel .painel-body {
  /*min-height: 150px;*/
  position: relative;
  padding: 5px 20px;
}

.painel .painel-fitheight {
  min-height: auto !important;
}

.painel .painel-nopaddingtb {
  padding: 0 !important;
}

.painel .pg-pesquisa .nav-link {
  background: #fff !important;
  color: #4f4f4f;
  border: none;
  border-bottom: 0.5px solid #d8d8d8;
  padding: 7px 20px;
  text-align: center;
  flex: 1 1;
}
.nav-tabs .nav-link {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.painel .pg-pesquisa .nav-link.active {
  background: #6259ca !important;
  color: #fff;
}

.painel .pg-pesquisa .nav-tabs {
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.painel .painel-body.overflow {
  overflow-y: auto;
  max-height: 250px !important;
}

.loading {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  top: 0;
  padding-top: 50px;
}

.dropzone {
  border: 2px dashed #dcd9f5;
  border-radius: 3px;
  background-color: #f2f1ff;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.85;
  cursor: pointer;
  outline: none;
  min-height: 150px;
  background-image: url(/static/media/fancy_upload.53febf4a.png);
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e8e8f7;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.tableContainer {
  position: relative;
}

.tableContainer .tableLoading {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;
}

.tableLoading .loadingContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.pagination li {
  display: block;
  float: left;
  margin-right: 10px;
}
.pagination .active a,
.pagination li a:hover {
  color: #0062cc;
  background: #fff;
  border: 1px solid #0069d9;
  padding: 4px 9px;
}
.pagination li a {
  display: block;
  padding: 5px 10px;
  cursor: pointer;
  background: #0069d9;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #444444;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444444;
}

/*
.painel .painel-body table thead tr th {
  border-top: none;
}

.painel .painel-body table thead tr th {
  border-bottom: none;
}
*/

.painel .painel-body table thead tr th {
  font-weight: bolder;
}

.painel .painel-footer {
  border-top: 0.5px solid #d8d8d8;
  padding: 15px 20px;
  font-weight: bold;
}

.painel .painel-footer button {
  border: none;
  background: none;
  color: #444444;
}
.painel .painel-footer button svg {
  margin-left: 8px;
}

.clear {
  clear: both;
}

.MuiButton-contained {
  box-shadow: none !important;
}

.modal-title {
  font-size: 16px;
  font-weight: 700;
  color: #4a526f;
  line-height: 1;
  margin-bottom: 0;
  line-height: 1.5;
  padding: 10px;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e8e8f7;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  align-items: center;
  padding: 0px;
}

form .form-label {
  font-size: 11px;
  color: #a8afc7;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.2px;
}

form .row {
  margin-bottom: 10px;
}

form .buttons {
  margin-top: 40px;
}

form .buttons button {
  width: 120px;
}

form .buttons .send {
  background: #444444;
  border-radius: 10px !important;
  margin-right: 20px;
}

form .buttons .reset {
  background: #fff;
  color: #444444;
  border: 1px solid #444444;
  border-radius: 10px !important;
}

.left-column {
  border-right: 1px solid #d8d8d8;
}

.right-column {
  padding-left: 20px;
}

@media (max-width: 800px) {
  /* .menu {
    display: none;
  }

  .menu nav ul li {
    width: 100%;
  } */

  .topbar .search {
    display: none;
  }

  .topbar .search_mobile {
    display: flex;
  }

  .topSearchResult {
    top: 43px;
  }

  .topbar .search_mobile button {
    margin-left: 20px;
    background: none;
    border: none;
    font-size: 30px;
  }
}

.status-active,
.status-inactive {
  padding: 5px 20px;
  color: #fff;
  border-radius: 8px;
}

.status-active {
  background-color: #19b159;
}

.status-active:hover {
  background-color: #37ce4f;
}

.status-inactive {
  background-color: #989998;
}

.status-inactive:hover {
  background-color: #676867;
}

.cards-wrapper {
  padding: 50px 70px 50px 70px;
}

.card-red-content {
  margin-top: 15px;
  background: #ed213a;
  background: linear-gradient(to bottom, #93291e, #ed213a);
  width: 250px;
  height: 200px;
  box-shadow: 0 0 10px 2px #c7c7c7;
  padding: 25px;
}

.card-green-content {
  margin-top: 15px;
  background: #348f50;
  background: linear-gradient(to top, #56b4d3, #348f50);

  width: 250px;
  height: 200px;
  box-shadow: 0 0 10px 2px #c7c7c7;
  padding: 25px;
}

.card-yellow-content {
  margin-top: 15px;
  background: #f2994a;
  background: linear-gradient(to top, #f2c94c, #f2994a);

  width: 250px;
  height: 200px;
  box-shadow: 0 0 10px 2px #c7c7c7;
  padding: 25px;
}

.card-default-content {
  margin-top: 15px;
  background: #373b44;
  background: linear-gradient(to top, #4286f4, #373b44);

  width: 250px;
  height: 200px;
  box-shadow: 0 0 10px 2px #c7c7c7;
  padding: 25px;
}

.card-divida-content {
  margin-top: 15px;
  background: #e0e0e0;
  width: 250px;
  height: 200px;
  border-radius: 20px;
  box-shadow: 0 0 10px 2px #c7c7c7;
  padding: 25px;
  border: 0.5px solid #333;
}

.card-divida-description {
  color: #333;
  font-size: 25px;
  margin-top: 100px;
  position: absolute;
}

.card-divida-value {
  color: #333;
  font-size: 65px;
  margin-top: 30px;
  position: absolute;
}

.card-divida-bg-icon {
  width: 130px;
  z-index: -1;
  opacity: 0.2;
  margin-top: 20px;
  margin-left: 120px;
}

.card-description {
  color: #fff;
  font-size: 25px;
  margin-top: 100px;
  position: absolute;
}

.card-value {
  color: #fff;
  font-size: 65px;
  margin-top: 30px;
  position: absolute;
}

.card-bg-icon {
  font-size: 130px;
  color: #fff;
  z-index: -1;
  opacity: 0.2;
  margin-top: 20px;
  margin-left: 120px;
}

.alignMiddle {
  display: flex;
  width: 100%;
  flex-shrink: 0;
}

.controlpanel-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.controlpanel-item {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-end;
}
.controlpanel-item .btn {
  min-width: 150px;
  border-radius: 4px;
  transition: none;
  min-height: 34px;
  display: inline-block;
  font-weight: 400;
  color: #fff;
  background-color: #6259ca;
  -webkit-user-select: none;
          user-select: none;
  padding: 1px 10px;
  font-size: 0.875rem;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controlpanel-item .btn-fit {
  width: auto !important;
}

.bg-white {
  background-color: #fff !important;
}

.badge {
  font-weight: 500;
  padding: 4px 6px 5px;
  display: inline-block;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge.bg-secondary {
  background-color: #f1388b !important;
  color: #fff;
}

.badge.bg-success {
  color: #ffffff !important;
  background-color: #19b159 !important;
}

.MuiAutocomplete-root {
  width: 100%;
}

.button-container {
  margin-top: 10px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.button-container button {
  margin-left: 15px;
}
.MuiTab-wrapper {
  font-family: 'Inter', sans-serif !important;
  font-weight: bolder;
  text-transform: inherit !important;
  font-size: 14px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.modal-padding {
  padding: 8px;
}

.MuiTable-root {
  border: none !important;
}

.MuiTableCell-root {
  font-size: 1.09rem !important;
  font-size: 12px !important;
  border: none !important;
}

.flex-direction {
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkicon {
  margin-right: 7px;
}

.flex-direction a {
  margin-right: 7px;
}

.flex-direction a:last-child {
  margin-right: 0;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #6259ca !important;
}

.MuiTableSortLabel-icon {
  display: none !important;
}

.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  display: block !important;
}

.MuiAppBar-colorPrimary {
  background: #fff !important;
  color: #7f7f7f !important;
}

.MuiTab-fullWidth {
  /*border-right: 1px solid #fff !important;*/
}

.MuiTab-textColorInherit {
  opacity: 1 !important;
  transition: background-color 200ms;
}
.MuiTab-textColorInherit:hover {
  opacity: 1 !important;
  background: #e6e4f6 !important;
  color: #0e0e23 !important;
}
.MuiTab-textColorInherit.Mui-selected {
  opacity: 1 !important;
  background: #6259ca !important;
  color: #c8c6e5 !important;
}
.MuiTab-wrapper {
  font-size: 14px !important;
  display: inline-flex;
}

.MuiAlert-root {
  color: #856404 !important;
  background-color: #ffeeba !important;
  border-color: #ffeeba !important;
  border-radius: 8px !important;
}

.MuiAlert-standardInfo .MuiAlert-icon {
  color: #856404 !important;
}

.shadow-tab {
  box-shadow: 0 6px 4px -9px black;
  margin-bottom: 6px;
}

.MuiTab-wrapper {
  text-transform: capitalize !important;
  display: block !important;
}

.MuiTab-labelIcon {
  min-height: 48px !important;
}

.MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
  margin-right: 10px;
}

.MuiTypography-body1 {
  font-family: 'Inter', sans-serif !important;
}

.MuiSelect-select.MuiSelect-select {
  font-family: 'Inter', sans-serif !important;
}

.MuiButton-label {
  font-family: 'Inter', sans-serif !important;
}

.normal-letter-spacing {
  letter-spacing: normal;
}

.border-top {
  border-top: 1px solid #e8e8f7;
}

a {
  color: #333 !important;
}

:root {
  --primary-bg-color: #6259ca;
  --primary-bg-hover: #403fad;
  --primary-transparentcolor: rgba(98, 89, 202, 0.16);
  --primary-bg-border: #6259ca;
  --dark-theme: #0e0e23;
  --dark-body: #24243e;
  --dark-border: rgba(255, 255, 255, 0.15);
  --dark-color: #d0d0e2;
  --dark-primary: #6259ca;
  --indigo: #4b0082;
  --purple: #6f42c1;
  --pink: #f1388b;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --light-gb: #eeeeee;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}

#login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#login .content {
  width: 60%;
  height: 80%;
  min-height: 300px;
  background: #000;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  border-bottom-right-radius: 30px;
  box-shadow: 2px 2px 5px black;
}

#login .content .introduction {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100%;
}

#login .content .login-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  height: 100%;
  background: #fff;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 20px;
}

#login .content .login-content img {
  display: none;
}

#login .content .login-content .login-title {
  width: 100%;
  margin-bottom: 15px;
}

#login .content .login-content .loginForm {
  width: 100%;
}

#login .content .login-content .loginForm form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#login .content .login-content .loginForm form input {
  margin-bottom: 10px;
  padding: 5px;
  border: none;
  border-bottom: 1px solid #999;
  width: 100%;
}

#login .content .login-content .loginForm button {
  background: #040e21;
  color: #fff;
  width: 60%;
  padding: 8px 0;
  border: none;
  border-radius: 5px;
  margin: 10px 0 15px 0;
}

.loginError {
  width: 100%;
  padding: 15px;
  text-align: center;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  margin-top: 12px;
}

#login .login-password {
  width: 100%;
  display: flex;
}

#login .login-password input {
  padding: 23px 5px !important;
}

#login .login-password button {
  width: 40px !important;
  height: 20px !important;
}

/* twoFactor =============================================== */
#twofactor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#twofactor .content {
  width: 60%;
  height: 80%;
  min-height: 300px;
  background: #000;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  border-bottom-right-radius: 30px;
  box-shadow: 2px 2px 5px black;
}

#twofactor .content .introduction {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100%;
}

#twofactor .content .twofactor-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  height: 100%;
  background: #fff;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 20px;
}

#twofactor .content .twofactor-content img {
  display: none;
}

.twofactor-title {
  width: 100%;
  margin-bottom: 15px;
}

.twofactorForm {
  width: 100%;
  text-align: center;
}

.twofactor-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.twofactor-form input {
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
  height: 50px;
  font-weight: bold !important;
  border: 1px solid #999;
  color: #040e21;
}

@media (max-width: 800px) {
  #login .content {
    background: none;
    box-shadow: none;
  }

  #login .content .introduction {
    display: none;
  }

  #login .content .login-content {
    width: 100%;
    box-shadow: 2px 2px 5px black;
    background: #040e21;
    color: #fff;
  }

  #login .content .login-content img {
    display: block;
    margin: 15px 0;
  }

  #login .content .login-title {
    display: none;
  }

  #login .content .login-content .loginForm form input {
    background: #040e21;
    color: #fff;
  }

  #login .content .login-content .loginForm button {
    background: #fff;
    color: #040e21;
  }

  #twofactor .content {
    background: none;
    box-shadow: none;
  }

  #twofactor .content .introduction {
    display: none;
  }

  #twofactor .content .twofactor-content {
    width: 100%;
    box-shadow: 2px 2px 5px black;
    background: #040e21;
    color: #fff;
  }

  #twofactor .content .twofactor-content img {
    display: block;
    margin: 15px 0;
  }

  #twofactor .content .twofactor-content .twofactorForm form input {
    color: #fff;
  }

  #twofactor .content .twofactor-title {
    display: none;
  }
}

.recaptcha {
  margin-top: 15px;
}

.error__captcha {
  color: #fff;
  background: rgb(252, 8, 8);
  padding: 10px;
  border-radius: 30px;
}

.forgot__pass {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.forgot-password-spacing {
  padding: 21px 0px 21px 0;
}
.loading-application {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgb(28, 106, 250);
}
.loading-application img {
  margin-bottom: 40px;
  display: block;
  margin: 0 auto;
}
.loading-application .dados {
  text-align: center;
  color: #fff;
}

.loading-application .dados h4 {
  margin-top: 30px;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.main-wrapper {
    background: var(--light-gb);
    height: 100vh;
}
* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.main-wrapper {
    background: var(--light-gb);
    height: 100vh;
}

.painel-title-controls {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: '100%';
}

.painel-title-controls strong{
    margin-right: 24px;
}

.defaulttop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topbar {
  background: #fff;
  padding: 12px;
  border-bottom: 1px solid #e1e6f1;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(225, 230, 241);
  box-shadow: -7.829px 11.607px 20px 0px #908f8f1a;
  transition: 0.3s ease;
}

.small {
  width: auto;
  margin-left: 180px;
}

.topbar-fixed {
  position: fixed;
  width: calc(100% - 70px);
}

.topbar-menu {
  position: relative;
  margin-bottom: 70px;
  z-index: 10;
  width: 100%;
}

.topbar .search {
  position: relative;
}

.topbar .profile {
  position: relative;
}

.topbar .profile strong {
  font-size: 13px;
  color: #444444;
}

.topbar .profile button {
  border-radius: 100px;
  margin-right: 10px;
  font-weight: bold;
  text-decoration: none;
  background: none;
  border: none;
  color: #444444;
}

.topbar .profile .menuProfile {
  position: absolute;
  top: 35px;
  left: 0;
  background: #fff;
  width: 100%; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */ /* Firefox 3.5 - 3.6 */
  box-shadow: 3px 3px 5px 6px #ccc; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  padding: 10px;
  z-index: 1000;
}

.topbar .search_mobile {
  display: none;
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.topbar .search_mobile input {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 100px;
  border: none;
  padding: 10px 15px;
  width: 100%;
}

.topbar .search input {
  min-width: 250px;
  border-radius: 0 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.topbar .search input.searchLoad {
  background-size: 25px 25px;
  background-position: 10px 50%;
}
.topbar .search .search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 10px;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #333;
}

.topbar .search .search-bar .advancedSearchIcon {
  font-size: 15px;
  padding: 6px 12px 6px 10px;
  background: #6259ca;
  border: 1px #6259ca solid;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.topSearchResult {
  position: absolute;
  background: #fff;
  width: 100%;
  padding: 10px;
  z-index: 1000;
  max-height: 250px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.75) 16px 20px 36px 2px;
}
.topSearchResult ul {
  list-style: none;
}

.topSearchResult ul li {
  border-bottom: 1px solid #999;
}

.topSearchResult ul li .linkicon {
  text-decoration: none;
  display: block;
  padding: 10px;
  cursor: pointer;
}

.topSearchResult ul li .linkicon:hover {
  background: #e5f1ff;
}

.topSearchResult ul li span {
  display: block;
  font-size: 10px;
}

.topSearchResult ul li:last-child {
  border-bottom: none;
}

.topbar .actions {
  margin-right: 10px;
  display: flex;
}

.topbar .actions .action-menu {
  margin-left: 15px;
}

.topbar .actions .action-menu .MuiAvatar-root:hover {
  cursor: pointer;
}

.notifications {
  text-align: center;
  font-size: 12px;
}

.logout-wrapper {
  display: flex;
  position: absolute;
  background: #fff;
  width: 180px;
  height: 60px;
  z-index: 1;
  top: 65px;
  right: 0;
  box-shadow: 10px 10px 20px 2px #cccccc;
  padding: 25px 25px 25px 25px;
  justify-content: center;
  align-content: center;
}

.sair {
  margin-top: -10px;
  color: #444444;
  font-weight: bold;
}

.logout-icon {
  margin-left: 10px;
  color: #444444;
  margin-top: -5px;
}

.logout {
  margin-top: -10px;
  cursor: pointer;
}

.username {
  margin-right: 15px;
  color: #444444;
  font-weight: bold;
  cursor: pointer;
}

.hamburguer {
  font-size: 25px;
  margin-left: 13px;
  margin-top: 5px;
}

.blueWord {
  color: #444444;
}

.topbar .current-company {
  position: absolute;
}

.text-modal {
  padding: 10px 0px 10px 0px;
}

.color-validation {
  border-color: #f44336;
  background-color: #ffcdd2;
}

:root {
  --menu-inactive: #eaedf771;
  --menu-active: #eaedf7;
  --primary-bg-color: #6259ca;
  --primary-bg-hover: #403fad;
  --primary-transparentcolor: rgba(98, 89, 202, 0.16);
  --primary-bg-border: #6259ca;
  --dark-theme: #0e0e23;
  --dark-body: #24243e;
  --dark-border: rgba(255, 255, 255, 0.15);
  --dark-color: #d0d0e2;
  --dark-primary: #6259ca;
}

.menu {
  width: auto;
  min-width: 80px;
  position: relative;
  height: 100%;
  min-height: 100%;
}

.menu-fixed {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100%;
  z-index: 10;
}

.menu-container,
.menu-click,
.menu-wrapped-click {
  background-color: #7f7f7f;
  width: 80px;
  min-width: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #fff;
  transition: 0.1s;
  overflow: hidden;
  transition: 0.3s ease;
  min-height: 100%;
}

.menu-container svg {
  color: #fff;
}

.menu-container .menu-toolbar {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  height: 65px;
  background-color: var(--dark-theme);
}

.menu-footer {
  position: absolute;
  bottom: 0;
}

.menu-container .menu-toolbar button {
  outline: none;
}

.menu-container .menu-toolbar img,
.menu-wrapped .menu-toolbar img {
  width: 50px;
  height: auto;
}

.menu-wrapped .menu-toolbar img {
  display: inherit;
}

.menu-container .menu-header,
.menu-wrapped .menu-header {
  height: 140px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.menu-wrapped .menu-header {
  min-height: 0px;
  height: 0px;
}

.menu-wrapped .menu-header span,
.menu-wrapped .menu-header strong {
  display: inherit;
}

.menu-wrapped .menu-header span,
.menu-wrapped .menu-header strong {
  display: none;
}

.menu-container .menu-header span,
.menu-wrapped .menu-header span {
  font-size: 10px;
  color: darkgray;
}

.menu-container .menu-header .avatar,
.menu-wrapped .menu-header .avatar {
  height: 80px;
  width: 80px;
  background-color: var(--dark-theme);
  border-radius: 50%;
  position: absolute;
  bottom: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-wrapped .menu-header .avatar {
  height: 40px;
  width: 40px;
}

.menu-container .menu-header .avatar img,
.menu-wrapped .menu-header .avatar img {
  height: 72px;
  width: 72px;
}

.menu-wrapped .menu-header .avatar img {
  height: 32px;
  width: 32px;
}

.menu-container .menu-content {
  display: flex;
  flex: 1 1;
  background-color: var(--dark-theme);
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  min-height: 100%;
  padding-top: 5px;
}

.menu-container .menu-content strong {
  font-size: 11px;
  color: darkgray;
}

.menu-container .menu-content span {
  font-size: 12px;
}

.menu-container .menu-content ul {
  padding: 16px 0 0 0;
}

.menu-container .menu-content ul li {
  padding: 8px 12px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 0 16px 16px 0;
  transition: background-color 0.5s;
  width: calc(100% - 16px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-container .menu-content ul li a {
  text-decoration: none;
  color: #ffffff;
  width: 100%;
  height: 100%;
}

.menu-container .menu-content ul li span {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.menu-container .menu-content ul li svg,
.menu-wrapped .menu-content ul li svg {
  margin-right: 8px;
}

.menu-wrapped .menu-content ul li svg {
  margin-right: 24px;
}

.menu-container .menu-content .submenu,
.menu-wrapped .menu-content .submenu {
  display: inherit;
  background-color: rgb(17, 62, 114);
}

.menu-wrapped .menu-content .submenu {
  display: none;
}

.menu-container .menu-content .submenu li {
  padding: 8px 0px 8px 32px;
  list-style: none;
  min-height: 37px;
}

.menu-container .menu-content ul .selected {
  background-color: #7f7f7f;
}

.menu-container .menu-content ul li:hover {
  background-color: rgb(0, 41, 88);
}

.menu-container .menu-content ul .selected:hover {
  background-color: #3496eb;
}

.menu-content button .MuiIconButton-label {
  width: 35px !important;
  height: 35px;
  border-radius: 50%;
  font-size: 9px;
  line-height: 35px;
  text-align: left;
  margin-left: -20px;

  .active {
    margin-left: -13px !important;
    padding: 6px;
    margin: 1px;
    width: 35px !important;
    height: 35px;
    background: var(--primary-bg-color);
    color: var(--menu-active);
    border-radius: 50%;
    font-size: 9px;
    line-height: 35px;
    text-align: left;
  }
}

.menu,
.menu-fixed,
.menu-content,
.menu-wrapped,
.menu-container,
.menu-wrapped-click {
  .active {
    width: 260px !important;
    transition: max-width 0.3s ease-in-out;

    .menu-content button .MuiIconButton-label {
      margin-left: -198px !important;
    }
  }
}

.search-bar {
  .active {
    margin-left: +190px !important;
  }
}

.menu-content.active button .MuiIconButton-label {
  background: var(--primary-bg-color);
}

.menu-content button {
  width: 100%;
}

.menu-lateral {
  transition: 0.3s ease;
  margin-top: 0.6rem;
  padding-left: 15px;
  position: relative;
}

/* ======================================================= */
/*
.menu-lateral .active .shape1 {
  position: absolute;
  top: -30px;
  width: 20px;
  height: 60px;
  right: 0px;
  background-color: var(--menu-active);
}

.menu-lateral .active .shape2 {
  position: absolute;
  top: 35px;
  width: 20px;
  height: 30px;
  right: 0px;
  background-color: var(--menu-active);
}

.menu-lateral button:before,
.menu-lateral button:after {
  border-right: 21px solid var(--dark-theme);
  display: block !important;
}

.menu-lateral button:before {
  content: '';
  position: absolute;
  top: -30px;
  z-index: 9;
  right: 0px;
  bottom: 0;
  height: 30px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom: transparent;
  border-radius: 0px 0px 48px 0px;
}

.menu-lateral button:after {
  content: '';
  position: absolute;
  top: 41px;
  z-index: 0;
  right: 0px;
  bottom: 0;
  height: 30px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom: transparent;
  border-radius: 0px 48px 0px 0px;
} 
*/
/* ======================================================= */

.menu-item-wrapper {
  transition: max-height 0.4s ease-in-out;
  overflow: 'hidden';
}

.menu-lateral:hover {
  color: #333 !important;
}

.menu-lateral-item {
  background-color: var(--dark-theme) !important;
  padding: 3px !important;
  border-radius: 30px 0 0 30px !important;
  margin-bottom: 38px;
}

.menu-lateral-item.active {
  background-color: var(--menu-active) !important;
  transition: 0.3s;
}

.menu-lateral button svg {
  color: var(--menu-inactive) !important;
}

.menu-lateral:hover button svg {
  color: #fff !important;
}

.menu-item svg {
  color: var(--menu-inactive);
  margin-right: 24px;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 14px;
  margin-left: 24px;
  color: var(--menu-inactive);
}

.menu-item :hover {
  color: #fff;
  transition: 0.25s;
}

.MuiMenuItem-root {
  border-bottom: 1px solid #ddd !important;
}

.MuiMenuItem-root a {
  width: 100%;
  height: 100%;
}

.button-square {
    min-width: 32px !important;
  }
.color{
    color:red
}

.buscar-tipo-pessoa {
    margin-top: 28px
}

.color-validation {
    border-color: #F44336;
    background-color: #FFCDD2;

    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.Empresas--form__horario-funcionamento {
    display: flex;    

    
}

.Empresas--form__horario-funcionamento .MuiFormControl-root:not(:last-of-type) {
    margin-right: 32px;
}

.Empresas--form__horario-funcionamento .MuiFormControl-root input {
    width: 35px;
    margin-left: auto;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
}

.main-wrapper {
  background: var(--light-gb);
  height: 100vh;
}

.main-wrapper .form-empresas-wrapper {
  position: absolute;
  width: 80vw;
  max-height: 80vh;
  height: 800px;
  left: 335px;
  top: 18.5%;
  background: #fff;
  border-radius: 50px;
}

.register-user-title {
  position: relative;
  color: #4f4f4f;
  font-family: "Product Sans";
  font-size: 30px;
  font-weight: bold;
  top: 22px;
  left: 54px;
  letter-spacing: 0.03em;
}

.left-info .user-header-info {
  position: absolute;
  width: 325px;
  height: 18px;
  left: 50px;
  top: 90px;

  font-family: "Product Sans";
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;

  color: #444444;
}

/* START MEDIA QUERIE */

@media (min-width: 1300px) and (max-width: 1900px) {
  .register-user-title {
    font-size: 25px;
  }

  .left-info .user-header-info {
    width: 150px;
    font-size: 18px;
    top: 65px;
  }

  .left-info .username-wrapper .user-item {
    font-size: 15px;
    top: 155px;
  }

  .left-info .username-wrapper .user-info {
    font-size: 15px;
    top: 178px;
  }

  .left-info .username-wrapper .line-divisor {
    top: 205px;
  }

  .left-info .user-login .user-item {
    font-size: 15px;
    top: 125px;
  }

  .left-info .user-login .user-info {
    font-size: 15px;
    top: 150px;
  }

  .left-info .user-login .line-divisor {
    top: 178px;
  }

  .left-info .user-email .user-item {
    top: 93px;
  }

  .left-info .user-email .user-info {
    top: 115px;
  }

  .left-info .user-email .line-divisor {
    top: 145px;
  }

  .left-info .user-pass-exp .user-item {
    top: 60px;
  }

  .left-info .user-pass-exp .user-info {
    top: 83px;
  }

  .left-info .user-pass-exp .line-divisor {
    top: 115px;
  }

  .left-info .user-department .user-header-info {
    font-size: 15px;
    top: -70px;
  }

  .register-user-wrapper {
    width: auto;
  }

  .register-user-wrapper .right-info .side-divisor {
    width: 400px;
    left: 270px;
    top: 260px;
  }

  .register-user-wrapper .right-info .user-options-info {
    font-size: 18px;
    top: 65px;
    right: 160px;
  }

  .register-user-wrapper .right-info .options-inputs-admin {
    font-size: 15px;
    right: 465px;
    top: 110px;
  }

  .register-user-wrapper .right-info .options-inputs-restrict-ip {
    font-size: 15px;
    right: 205px;
    top: 110px;
  }

  .register-user-wrapper .right-info .options-inputs-restrict-ip .input-title {
    right: 20px;
  }

  .register-user-wrapper .right-info .options-inputs-restrict-hour {
    font-size: 15px;
    top: 110px;
    right: 35px;
  }

  .register-user-wrapper
    .right-info
    .options-inputs-restrict-hour
    .input-title {
    right: 15px;
  }

  .register-user-wrapper .right-info .checkbox-label {
    display: block;
    position: relative;
    margin: auto;
    cursor: pointer;
    line-height: 18px;
    height: 18px;
    width: 18px;
  }

  .register-user-wrapper .right-info .checkbox-label .checkbox-custom {
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border-radius: 3px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 2px solid #0080fc;
  }

  .register-user-wrapper
    .right-info
    .checkbox-label
    input:checked
    ~ .checkbox-custom {
    background-color: #0080fc;
    border-radius: 5px;
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }

  .register-user-wrapper .right-info .checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid #009bff;
    border-width: 0 3px 3px 0;
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
  }

  .register-user-wrapper
    .right-info
    .checkbox-label
    input:checked
    ~ .checkbox-custom::after {
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }

  .register-user-wrapper .right-info select {
    width: 50px;
  }
}

/* END MEDIA QUERIES */

.left-info .username-wrapper {
  position: absolute;
  top: -55px;
}

.left-info .user-item {
  position: absolute;
  width: 150px;
  height: 16px;
  left: 50px;
  top: 200px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.left-info .user-info {
  position: absolute;
  width: 140px;
  height: 16px;
  left: 50px;
  top: 230px;

  font-family: "Product Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #4f4f4f;
}

.left-info .line-divisor {
  background: #d8d8d8;
  height: 2px;
  position: absolute;
  width: 400px;
  left: 50px;
  top: 260px;
}

.left-info .user-login {
  position: absolute;
  top: 40px;
}

.left-info .user-login .line-divisor {
  width: 200px;
}

.left-info .user-login .user-pass .line-divisor {
  width: 150px;
}

.left-info .user-login .user-pass {
  position: absolute;
  left: 250px;
}

.left-info .user-login .user-email {
  position: absolute;
  top: 100px;
}

.left-info .user-login .user-email .line-divisor {
  width: 400px;
}

.left-info .user-login .user-pass-exp {
  position: absolute;
  top: 200px;
}

.left-info .user-login .user-pass-exp .user-item {
  width: 300px;
}

.left-info .user-login .user-pass-exp .line-divisor {
  width: 400px;
}

.left-info .user-login .user-department {
  position: absolute;
  top: 400px;
}

.left-info .user-login .user-department .department-select {
  position: absolute;
  font-family: "Product Sans";
  font-size: 17px;
  top: 120px;
  left: 50px;
  width: 400px;
}

.left-info .user-login .user-role {
  position: absolute;
  top: 500px;
}

.left-info .user-login .user-role .department-select {
  position: absolute;
  font-family: "Product Sans";
  font-size: 17px;
  top: 120px;
  left: 50px;
  width: 400px;
}

select {
  background: #fff;
  height: 45px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid #d8d8d8;
  color: #4f4f4f;
  font-weight: bold;
}

.right-info .side-divisor {
  position: absolute;
  width: 600px;

  height: 0px;
  left: 300px;
  top: 400px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  transform: rotate(90deg);
}

.right-info .user-options-info {
  position: absolute;
  width: 325px;
  height: 18px;
  right: 400px;
  top: 90px;

  font-family: "Product Sans";
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;

  color: #444444;
}

.right-info .options-inputs-admin {
  position: absolute;
  right: 699px;
  top: 150px;
  font-family: "Product Sans";
  font-size: 18px;
  font-weight: bold;
  color: #4f4f4f;
}

.right-info .options-inputs-admin .input-title {
  position: absolute;
  top: 3px;
  left: 40px;
}

.right-info .options-inputs-restrict-ip {
  position: absolute;
  right: 350px;
  top: 150px;
  font-family: "Product Sans";
  font-size: 18px;
  font-weight: bold;
  color: #4f4f4f;
  width: 300px;
}

.right-info .options-inputs-restrict-ip .input-title {
  position: absolute;
  top: 3px;
  right: 5px;
}

.right-info .options-inputs-restrict-hour {
  position: absolute;
  right: 100px;
  top: 150px;
  font-family: "Product Sans";
  font-size: 18px;
  font-weight: bold;
  color: #4f4f4f;
  width: 300px;
}

.right-info .options-inputs-restrict-hour .input-title {
  position: absolute;
  top: 3px;
  right: 0;
}

.right-info .checkbox-label {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  line-height: 24px;
  height: 24px;
  width: 24px;
  clear: both;
}

.right-info .checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.right-info .checkbox-label .checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 3px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #0080fc;
}

.right-info .checkbox-label input:checked ~ .checkbox-custom {
  background-color: #0080fc;
  border-radius: 5px;
  transform: rotate(0deg) scale(1);
  opacity: 1;
}

.right-info .checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #009bff;
  border-width: 0 3px 3px 0;
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.right-info .checkbox-label input:checked ~ .checkbox-custom::after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

.right-info .status-wrapper {
  position: absolute;
  top: -40px;
  right: 0;
}
.right-info .status-wrapper .user-item {
  position: absolute;
  width: 47px;
  height: 16px;
  right: 675px;
  top: 250px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.right-info .status-wrapper .status-select {
  position: absolute;
  font-family: "Product Sans";
  font-size: 17px;
  top: 280px;
  right: 100px;
  width: 622px;
}

.right-info .status-wrapper .user-item {
  position: absolute;
  width: 47px;
  height: 16px;
  right: 675px;
  top: 250px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.right-info .status-wrapper .status-select {
  position: absolute;
  font-family: "Product Sans";
  font-size: 17px;
  top: 280px;
  right: 100px;
  width: 622px;
}

.right-info .phone-wrapper {
  position: absolute;
  right: 0;
  top: -50px;
}

.right-info .phone-wrapper .cel-item .user-item {
  position: absolute;
  width: 47px;
  height: 16px;
  right: 675px;
  top: 360px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.right-info .phone-wrapper .cel-item .user-info {
  position: absolute;
  width: 140px;
  height: 16px;
  right: 580px;
  top: 400px;

  font-family: "Product Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #4f4f4f;
}

.right-info .phone-wrapper .cel-item .line-divisor {
  background: #d8d8d8;
  height: 2px;
  position: absolute;
  width: 245px;
  right: 473px;
  top: 430px;
}

.right-info .phone-wrapper .phone-item .user-item {
  position: absolute;
  width: 47px;
  height: 16px;
  right: 300px;
  top: 360px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.right-info .phone-wrapper .phone-item .user-info {
  position: absolute;
  width: 140px;
  height: 16px;
  right: 206px;
  top: 400px;

  font-family: "Product Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #4f4f4f;
}

.right-info .phone-wrapper .phone-item .line-divisor {
  background: #d8d8d8;
  height: 2px;
  position: absolute;
  width: 245px;
  right: 100px;
  top: 430px;
}

.right-info .hour-wrapper {
  position: absolute;
  top: 70px;
  right: 0;
}

.right-info .hour-wrapper .hour-init-item .user-item {
  position: absolute;
  width: 150px;
  height: 16px;
  right: 570px;
  top: 360px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.right-info .hour-wrapper .hour-init-item .user-info {
  position: absolute;
  width: 140px;
  height: 16px;
  right: 580px;
  top: 400px;

  font-family: "Product Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #4f4f4f;
}

.right-info .hour-wrapper .hour-init-item .line-divisor {
  background: #d8d8d8;
  height: 2px;
  position: absolute;
  width: 245px;
  right: 473px;
  top: 430px;
}

.right-info .hour-wrapper .hour-finish-item .user-item {
  position: absolute;
  width: 150px;
  height: 16px;
  right: 198px;
  top: 360px;

  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;

  color: #0080fc;
}

.right-info .hour-wrapper .hour-finish-item .user-info {
  position: absolute;
  width: 140px;
  height: 16px;
  right: 206px;
  top: 400px;

  font-family: "Product Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #4f4f4f;
}

.right-info .hour-wrapper .hour-finish-item .line-divisor {
  background: #d8d8d8;
  height: 2px;
  position: absolute;
  width: 245px;
  right: 100px;
  top: 430px;
}

.right-info .actions-wrapper {
  position: absolute;
  right: 100px;
  top: 580px;
}

.right-info .actions-wrapper .action-send {
  position: absolute;
  top: -20px;
  right: 0;
}

.right-info .actions-wrapper .send-btn {
  width: 620px;
  height: 50px;
  text-transform: uppercase;
  font-size: 17px;
  font-family: "Product Sans";
  color: #fff;
  background: #0080fc;
  border-radius: 4px;
  animation: none;
  border: none;
  letter-spacing: 0.05em;
}

.right-info .actions-wrapper .send-btn:hover {
  cursor: pointer;
  background: #009bff;
  transition: 0.3s;
  border: 1px solid #444444;
}

.right-info .actions-wrapper .action-reset {
  position: absolute;
  top: 40px;
  right: 0;
}

.right-info .actions-wrapper .empty-btn {
  width: 620px;
  height: 50px;
  text-transform: uppercase;
  font-size: 17px;
  font-family: "Product Sans";
  color: #4f4f4f;
  background: #d8d8d8;
  border-radius: 4px;
  animation: none;
  border: none;
  letter-spacing: 0.05em;
}

.right-info .actions-wrapper .empty-btn:hover {
  cursor: pointer;
  background: rgb(190, 189, 189);
  transition: 0.3s;
  border: 1px solid rgb(177, 177, 177);
}

.linkicon {
  color: #212529;
}
.linkicon:hover {
  color: #212529;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.main-wrapper {
    background: var(--light-gb);
    height: 100vh;
}
.list-departamento-container .list-departamento-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.edittpimg {
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}
.list-tipodepartamento-container .list-tipodepartamento-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

.line-components {
    display: flex;
    align-items: center;
}

.line-components label{
    margin-right: 12px;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

.line-components {
    display: flex;
    align-items: center;
}

.line-components label{
    margin-right: 12px;
}
.list-categoria-container .list-categoria-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

.line-components {
    display: flex;
    align-items: center;
}

.line-components label{
    margin-right: 12px;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

.line-components {
    display: flex;
    align-items: center;
}

.line-components label{
    margin-right: 12px;
}
.list-tribunal-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.main-wrapper {
    background: var(--light-gb);
    height: 100vh;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.edittpimg {
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}
.list-tribunal-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-tribunal-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.main-wrapper {
    background: var(--light-gb);
    height: 100vh;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.edittpimg {
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

#zoom {
  display: flex;
}

#zoom img {
  width: 100%;
  object-fit: cover;
}



.image-viewer {
  height: 100%;
  min-height: 70vh;
  width: 100%;
}

.image--viewer__viewer {
  position: relative;
  overflow: auto;
  text-align: center;
  width: 100%;
}

.pdfviewer {
  height: 100%;
  width: 100%;
}
.viewer-toolbar-current-page-input {
  width: 25px !important;
}
.viewer-layout-toolbar {
  font-size: 9px !important;
}

.viewer-toolbar-right div:nth-of-type(2),
.viewer-toolbar-right div:nth-of-type(3),
.viewer-toolbar-right div:nth-of-type(4) {
  display: none !important;
}

.bottomThumbViewer {
  font-size: 12px;
  font-weight: bold;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomThumbViewer span {
  color: #999;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomThumbViewer span svg {
  font-size: 16px;
}

.dialog-preview-images .navigator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
}

#modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e8e8f7;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  align-items: center;
  padding: 0px;
}

.itemChecklistName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.formalizacao-forms {
  overflow-x: hidden;
  max-height: 400px;
}

.itemChecklistName {
  text-wrap: wrap !important;
}

.itemChecklistName svg {
  margin-left: 5px;
}

.formalizacao-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.formalizacao-actions button {
  margin-left: 15px;
}

.formalizacao-motivos {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formalizacao-motivos span {
  margin-top: 4px;
}

.MuiTab-textColorInherit.Mui-selected {
  opacity: 1 !important;
  background: #fff !important;
  color: #000 !important;
}

.scrollableTabs {
  overflow-x: 'hidden';
}

.checklist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px
}

.checklist-header-search {
  display: flex;
  align-items: center;
  width: 80%;
}

.checklist-header-search .MuiFormControl-root {
  margin-right: 10px;
}

.checklist-header-search fieldset .MuiFormGroup-root {
  width: 300px;
}

.checklist-header-actions {
  width: auto;
}


.checklist-header .MuiFormControlLabel-root {
  margin-right: 10px;
}

/* Se a tela for menor que 600px */
@media (max-width: 1200px) {
  .checklist-header-search .MuiTextField-root {
    width: 180px;
  }

  .checklist-header-search fieldset .MuiFormGroup-root {
    width: 300px;
  }
}
h5 {
    font-weight: bolder;
}

.painel-content-formulario {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px !important;
}
.wire-title, 
.wire-line ,
.wire-block,
.form-user
{
    display: flex;
    align-items: center;
}

.wire-title {
    width: 100%;
    flex-direction: row;
}

.wire-title,
.wire-line {
    justify-content: space-between;
}

.button-refresh {
    border: 2px solid rgba(0, 0, 0, 0.09);
}

.button-add {
    width: 85px;
    height: 85px;
}

.button-preview {
    border: 2px solid rgba(0, 0, 0, 0.09);
}

.wire-block #simple-select {
    width: 80px;
    color: #3c4858;
}
.drag-n-drop-item,
.drag-n-drop-item-invisivel,
.title-items {
    background-color: white;
    display: flex;
    flex-Direction: 'row';
    justify-content: 'space-around';
    align-items: center;
    padding: 12px;
    width: 100%;
    margin: 8px 4px;
}

.drag-n-drop-item {
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.09);
    box-shadow:  2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}

.drag-n-drop-item-invisivel {
    font-style: italic;
    border-radius: 12px;
    color:gray;
    background-color: rgb(226, 226, 226);
}

.title-items {
    font-weight: bold;
}

.painel-body-preview {
    width: 100%;
    padding: 12px 36px;
    margin-top: 8px;
}

.painel-body-preview-item {
    margin-bottom: 8px;
}

.painel-body-message {
    width: 100%;
    min-height: 468px;
    padding: 12px 36px;
    margin: 8px 0;

    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-user {
    width: 100%;
    justify-content: center;
    background-color: #f3f3f3;
}

.form-user h3 {
    font-weight: bold;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);
}

.form-user input:disabled {
    background-color: rgb(228, 228, 228);
    color: rgb(87, 87, 87);
}

/* --------------- */
/* --------------- */

.wizard {
    border: 1px solid #e8e8f7;
    background-color: #ffffff;
}

/* STEPS **********************/

@media (min-width: 992px) {
    .wizard.vertical>.steps {
        padding: 25px;
        width: 40%;
    }

    .wizard>.steps {
        padding: 20px;
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.steps {
        width: 20%;
        float: left;
    }

    .wizard>.steps {
        padding: 25px;
    }
}

.wizard.vertical>.steps {
    padding: 20px;
}

.wizard>.steps .current a .number,
.wizard>.steps .current a:hover .number,
.wizard>.steps .current a:active .number {
    background-color: #6259ca;
    color: #fff;
    margin-right: 8px;
    border-radius: 3px;
    width: 30px;
    height: 30px;
}

.wizard>.steps a .number,
.wizard>.steps a:hover .number,
.wizard>.steps a:active .number {
    margin-right: 8px;
    flex-shrink: 0;
    font-weight: 700;
    background-color: #f0f0ff;
    color: #8f9cc0;
    display: block;
    text-align: center;
    line-height: 2;
    width: 30px;
    height: 30px;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
}

.wizard>.steps a {
    color: #a8afc7;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    transition: all 0.2s ease-in-out;
}

.wizard>.steps span {
    color: #a8afc7;
    font-size: 14px !important;
}

.wizard>.steps .current span {
    color: #8f9cc0;
}

.wizard>.steps .passed span {
    color: black;
}

.wizard>.steps .passed a .number,
.wizard>.steps .passed a:hover .number,
.wizard>.steps .passed a:active .number 
{
    background-color: #3c4858a9;
    border-radius: 50%;
    color:#f0f0ff;
}

.wizard li {
    list-style-type: none !important;
    display: flex;
    color: #6259ca;
}

/* CONTENT **********************/

@media (min-width: 992px) {
    .wizard.vertical>.content {
        width: 60%;
        padding: 25px;
    }

    .wizard>.content {
        padding: 20px;
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.content {
        border-top-width: 0;
        border-bottom-width: 0;
        width: 70%;
        float: right;
        border-left: 1px solid #e8e8f7;
    }

    .wizard>.content {
        padding: 25px;
    }
}

.wizard.vertical>.content {
    margin: 0;
    padding: 20px;
}

.wizard>.content {
    border-top: 1px solid #e8e8f7;
    border-bottom: 1px solid #e8e8f7;
    min-height: 150px;
    padding: 20px;
}

.wizard .actions .nav-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
/**********************/


.list-tribunal-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.main-wrapper {
    background: var(--light-gb);
    height: 100vh;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.edittpimg {
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}
.list-departamento-container .list-departamento-content {
    display: flex;
    justify-content: center;
    align-items: center;
}
.list-tribunal-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.main-wrapper {
    background: var(--light-gb);
    height: 100vh;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.edittpimg {
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}
.table-tipo-documento-container .table-tipo-documento-content {
    display: flex;
    align-items: center;
    justify-content: center;
}


* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.edittpimg {
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

.lista {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.lista li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #d8d8d8;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lista li:hover {
  background: #add3ff;
}

.lista li span {
  cursor: pointer;
}

.lista li:last-child {
  border: none;
}

.btn-back-type-document {
  margin-top: 5px;
}
.BPMNDiagram {
  width: 100%;
  height: 400px;
}

/**
 * color definitions
 */
.djs-container {
  --blue-base-65: #4d90ff;
  --blue-base-65-opacity-30: rgba(77, 144, 255, 0.3);
  --blue-darken-48: #005df7;
  --blue-darken-55: #1a70ff;
  --blue-lighten-82: #a2c5ff;

  --orange-base-60: #ffa533;
  --orange-base-60-opacity-30: rgba(255, 165, 51, 0.3);
  --orange-base-60-opacity-50: rgba(255, 165, 51, 0.5);
  --orange-lighten-85: #ffddb3;

  --red-base-62: #ff3d3d;
  --red-base-62-opacity-10: rgba(255, 61, 61, 0.1);

  --silver-darken-94: #efefef;

  --color-000000: #000000;
  --color-000000-opacity-05: rgba(0, 0, 0, 0.05);
  --color-000000-opacity-10: rgba(0, 0, 0, 0.1);
  --color-333333: #333333;
  --color-666666: #666666;
  --color-aaaaaa: #aaaaaa;
  --color-cccccc: #cccccc;
  --color-cdcdcd: #cdcdcd;
  --color-dddddd: #dddddd;
  --color-f6f6f6: #f6f6f6;
  --color-fafafa: #fafafa;
  --color-fefefe: #fefefe;
  --color-ffffff: #ffffff;

  --bendpoint-fill-color: var(--blue-base-65-opacity-30);
  --bendpoint-stroke-color: var(--blue-base-65);

  --context-pad-entry-background-color: var(--color-fefefe);
  --context-pad-entry-hover-background-color: var(--silver-darken-94);

  --element-dragger-color: var(--blue-base-65);
  --element-hover-outline-fill-color: var(--blue-darken-48);
  --element-selected-outline-stroke-color: var(--blue-base-65);

  --lasso-fill-color: var(--color-000000-opacity-05);
  --lasso-stroke-color: var(--color-000000);

  --palette-entry-color: var(--color-333333);
  --palette-entry-hover-color: var(--blue-darken-48);
  --palette-entry-selected-color: var(--blue-base-65);
  --palette-separator-color: var(--color-aaaaaa);
  --palette-toggle-hover-background-color: var(--color-666666);
  --palette-background-color: var(--color-fafafa);
  --palette-border-color: var(--color-cccccc);

  --popup-body-background-color: var(--color-fefefe);
  --popup-header-entry-selected-color: var(--blue-base-65);
  --popup-header-entry-selected-background-color: var(
    --color-000000-opacity-10
  );
  --popup-header-separator-color: var(--color-dddddd);
  --popup-background-color: var(--color-fafafa);
  --popup-border-color: var(--color-cccccc);

  --resizer-fill-color: var(--blue-base-65-opacity-30);
  --resizer-stroke-color: var(--blue-base-65);

  --search-container-background-color: var(--color-fafafa);
  --search-container-border-color: var(--blue-darken-55);
  --search-container-box-shadow-color: var(--blue-lighten-82);
  --search-container-box-shadow-inset-color: var(--color-cdcdcd);
  --search-input-border-color: var(--color-cccccc);
  --search-result-border-color: var(--color-aaaaaa);
  --search-result-highlight-color: var(--color-000000);
  --search-result-selected-color: var(--blue-base-65-opacity-30);

  --shape-attach-allowed-stroke-color: var(--blue-base-65);
  --shape-connect-allowed-fill-color: var(--color-000000-opacity-05);
  --shape-drop-allowed-fill-color: var(--color-000000-opacity-05);
  --shape-drop-not-allowed-fill-color: var(--red-base-62-opacity-10);
  --shape-resize-preview-stroke-color: var(--blue-base-65);

  --snap-line-stroke-color: var(--blue-base-65-opacity-30);

  --space-tool-crosshair-stroke-color: var(--color-000000);

  --tooltip-error-background-color: var(--red-base-62-opacity-10);
  --tooltip-error-border-color: var(--red-base-62);
  --tooltip-error-color: var(--red-base-62);
}

/**
 * outline styles
 */

.djs-outline {
  fill: none;
  visibility: hidden;
}

.djs-shape.selectable.hover .djs-outline,
.djs-shape.selectable.selected .djs-outline {
  visibility: visible;
  shape-rendering: geometricPrecision;
  /* stroke-dasharray: 3, 3; */

  stroke-width: 1px !important;
  stroke: #155cb5 !important;
  border-radius: 20px;
  fill: rgba(194, 213, 237, 0.4) !important;
  rx: 14px;
}

.djs-shape.selectable.selected .djs-outline {
  stroke: var(--element-selected-outline-stroke-color);
  stroke-width: 1px;
}

.djs-shape.selectable.hover .djs-outline {
  stroke: var(--element-hover-outline-fill-color);
  stroke-width: 1px;
}

.djs-shape.selectable {
  cursor: pointer;
}

.djs-shape.connect-ok .djs-visual > :nth-child(1) {
  fill: var(--shape-connect-allowed-fill-color) !important;
}

.djs-shape.connect-not-ok .djs-visual > :nth-child(1),
.djs-shape.drop-not-ok .djs-visual > :nth-child(1) {
  fill: var(--shape-drop-not-allowed-fill-color) !important;
}

.djs-shape.new-parent .djs-visual > :nth-child(1) {
  fill: var(--shape-drop-allowed-fill-color) !important;
}

svg.drop-not-ok {
  background: var(--shape-drop-not-allowed-fill-color) !important;
}

svg.new-parent {
  background: var(--shape-drop-allowed-fill-color) !important;
}

.djs-connection.connect-ok .djs-visual > :nth-child(1),
.djs-connection.drop-ok .djs-visual > :nth-child(1) {
  stroke: var(--shape-drop-allowed-fill-color) !important;
}

.djs-connection.connect-not-ok .djs-visual > :nth-child(1),
.djs-connection.drop-not-ok .djs-visual > :nth-child(1) {
  stroke: var(--shape-drop-not-allowed-fill-color) !important;
}

.drop-not-ok,
.connect-not-ok {
  stroke: var(--shape-attach-allowed-stroke-color) !important;
  cursor: not-allowed;
}

.djs-shape.attach-ok .djs-visual > :nth-child(1) {
  stroke-width: 5px !important;
}

.djs-frame.connect-not-ok .djs-visual > :nth-child(1),
.djs-frame.drop-not-ok .djs-visual > :nth-child(1) {
  stroke-width: 3px !important;
  stroke: var(--shape-drop-not-allowed-fill-color) !important;
  fill: none !important;
}

/**
* Selection box style
*
*/
.djs-lasso-overlay {
  fill: var(--lasso-fill-color);

  stroke-dasharray: 5 1 3 1;
  stroke: var(--lasso-stroke-color);

  shape-rendering: geometricPrecision;
  pointer-events: none;
}

/**
 * Resize styles
 */
.djs-resize-overlay {
  fill: none;

  stroke-dasharray: 5 1 3 1;
  stroke: var(--shape-resize-preview-stroke-color);

  pointer-events: none;
}

.djs-resizer-hit {
  fill: none;
  pointer-events: all;
}

.djs-resizer-visual {
  fill: var(--resizer-fill-color);
  stroke-width: 1px;
  stroke-opacity: 0.5;
  stroke: var(--resizer-stroke-color);
  shape-rendering: geometricprecision;
}

.djs-resizer:hover .djs-resizer-visual {
  stroke: var(--resizer-stroke-color);
  stroke-opacity: 1;
}

.djs-cursor-resize-ns,
.djs-resizer-n,
.djs-resizer-s {
  cursor: ns-resize;
}

.djs-cursor-resize-ew,
.djs-resizer-e,
.djs-resizer-w {
  cursor: ew-resize;
}

.djs-cursor-resize-nwse,
.djs-resizer-nw,
.djs-resizer-se {
  cursor: nwse-resize;
}

.djs-cursor-resize-nesw,
.djs-resizer-ne,
.djs-resizer-sw {
  cursor: nesw-resize;
}

.djs-shape.djs-resizing > .djs-outline {
  visibility: hidden !important;
}

.djs-shape.djs-resizing > .djs-resizer {
  visibility: hidden;
}

.djs-dragger > .djs-resizer {
  visibility: hidden;
}

/**
 * drag styles
 */
.djs-dragger * {
  fill: none !important;
  stroke: var(--element-dragger-color) !important;
}

.djs-dragger tspan,
.djs-dragger text {
  fill: var(--element-dragger-color) !important;
  stroke: none !important;
}

marker.djs-dragger circle,
marker.djs-dragger path,
marker.djs-dragger polygon,
marker.djs-dragger polyline,
marker.djs-dragger rect {
  fill: var(--element-dragger-color) !important;
  stroke: none !important;
}

marker.djs-dragger text,
marker.djs-dragger tspan {
  fill: none !important;
  stroke: var(--element-dragger-color) !important;
}

.djs-dragging {
  opacity: 0.3;
}

.djs-dragging,
.djs-dragging > * {
  pointer-events: none !important;
}

.djs-dragging .djs-context-pad,
.djs-dragging .djs-outline {
  display: none !important;
}

/**
 * no pointer events for visual
 */
.djs-visual,
.djs-outline {
  pointer-events: none;
}

.djs-shape.attach-ok .djs-hit {
  stroke-width: 60px !important;
}

/**
 * all pointer events for hit shape
 */
.djs-shape > .djs-hit-all {
  pointer-events: all;
}

.djs-shape > .djs-hit-stroke,
.djs-shape > .djs-hit-click-stroke {
  pointer-events: stroke;
}

/**
 * all pointer events for hit shape
 */
.djs-drag-active .djs-shape > .djs-hit-click-stroke {
  pointer-events: all;
}

/**
 * shape / connection basic styles
 */
.djs-connection .djs-visual {
  stroke-width: 2px;
  fill: none;
}

.djs-cursor-grab {
  cursor: grab;
}

.djs-cursor-grabbing {
  cursor: grabbing;
}

.djs-cursor-crosshair {
  cursor: crosshair;
}

.djs-cursor-move {
  cursor: move;
}

.djs-cursor-resize-ns {
  cursor: ns-resize;
}

.djs-cursor-resize-ew {
  cursor: ew-resize;
}

/**
 * snapping
 */
.djs-snap-line {
  stroke: var(--snap-line-stroke-color);
  stroke-linecap: round;
  stroke-width: 2px;
  pointer-events: none;
}

/**
 * snapping
 */
.djs-crosshair {
  stroke: var(--space-tool-crosshair-stroke-color);
  stroke-linecap: round;
  stroke-width: 1px;
  pointer-events: none;
  shape-rendering: crispEdges;
  stroke-dasharray: 5, 5;
}

/**
 * palette
 */

.djs-palette {
  position: absolute;
  left: 20px;
  top: 20px;

  box-sizing: border-box;
  width: 48px;
}

.djs-palette .separator {
  margin: 0 5px;
  padding-top: 5px;

  border: none;
  border-bottom: solid 1px var(--palette-separator-color);

  clear: both;
}

.djs-palette .entry:before {
  vertical-align: text-bottom;
}

.djs-palette .djs-palette-toggle {
  cursor: pointer;
}

.djs-palette .entry,
.djs-palette .djs-palette-toggle {
  color: var(--palette-entry-color);
  font-size: 30px;

  text-align: center;
}

.djs-palette .entry {
  float: left;
}

.djs-palette .entry img {
  max-width: 100%;
}

.djs-palette .djs-palette-entries:after {
  content: '';
  display: table;
  clear: both;
}

.djs-palette .djs-palette-toggle:hover {
  background: var(--palette-toggle-hover-background-color);
}

.djs-palette .entry:hover {
  color: var(--palette-entry-hover-color);
}

.djs-palette .highlighted-entry {
  color: var(--palette-entry-selected-color) !important;
}

.djs-palette .entry,
.djs-palette .djs-palette-toggle {
  width: 46px;
  height: 46px;
  line-height: 46px;
  cursor: default;
}

/**
 * Palette open / two-column layout is controlled via
 * classes on the palette. Events to hook into palette
 * changed life-cycle are available in addition.
 */
.djs-palette.two-column.open {
  width: 94px;
}

.djs-palette:not(.open) .djs-palette-entries {
  display: none;
}

.djs-palette:not(.open) {
  overflow: hidden;
}

.djs-palette.open .djs-palette-toggle {
  display: none;
}

/**
 * context-pad
 */
.djs-overlay-context-pad {
  width: 72px;
}

.djs-context-pad {
  position: absolute;
  display: none;
  pointer-events: none;
}

.djs-context-pad .entry {
  width: 22px;
  height: 22px;
  text-align: center;
  display: inline-block;
  font-size: 22px;
  margin: 0 2px 2px 0;

  border-radius: 3px;

  cursor: default;

  background-color: var(--context-pad-entry-background-color);
  box-shadow: 0 0 2px 1px var(--context-pad-entry-background-color);
  pointer-events: all;
}

.djs-context-pad .entry:before {
  vertical-align: top;
}

.djs-context-pad .entry:hover {
  background: var(--context-pad-entry-hover-background-color);
}

.djs-context-pad.open {
  display: block;
}

/**
 * popup styles
 */
.djs-popup .entry {
  line-height: 20px;
  white-space: nowrap;
  cursor: default;
}

/* larger font for prefixed icons */
.djs-popup .entry:before {
  vertical-align: middle;
  font-size: 20px;
}

.djs-popup .entry > span {
  vertical-align: middle;
  font-size: 14px;
}

.djs-popup .entry:hover,
.djs-popup .entry.active:hover {
  background: var(--popup-header-entry-selected-background-color);
}

.djs-popup .entry.disabled {
  background: inherit;
}

.djs-popup .djs-popup-header .entry {
  display: inline-block;
  padding: 2px 3px 2px 3px;

  border: solid 1px transparent;
  border-radius: 3px;
}

.djs-popup .djs-popup-header .entry.active {
  color: var(--popup-header-entry-selected-color);
  border: solid 1px var(--popup-header-entry-selected-color);
  background-color: var(--popup-header-entry-selected-background-color);
}

.djs-popup-body .entry {
  padding: 4px 10px 4px 5px;
}

.djs-popup-body .entry > span {
  margin-left: 5px;
}

.djs-popup-body {
  background-color: var(--popup-body-background-color);
}

.djs-popup-header {
  border-bottom: 1px solid var(--popup-header-separator-color);
}

.djs-popup-header .entry {
  margin: 1px;
  margin-left: 3px;
}

.djs-popup-header .entry:last-child {
  margin-right: 3px;
}

/**
 * popup / palette styles
 */
.djs-palette {
  background: var(--palette-background-color);
  border: solid 1px var(--palette-border-color);
  border-radius: 2px;
}

.djs-popup {
  background: var(--popup-background-color);
  border: solid 1px var(--popup-border-color);
  border-radius: 2px;
}

/**
 * touch
 */

.djs-shape,
.djs-connection {
  touch-action: none;
}

.djs-segment-dragger,
.djs-bendpoint {
  display: none;
}

/**
 * bendpoints
 */
.djs-segment-dragger .djs-visual {
  display: none;

  fill: var(--bendpoint-fill-color);
  stroke: var(--bendpoint-stroke-color);
  stroke-width: 1px;
  stroke-opacity: 1;
}

.djs-segment-dragger:hover .djs-visual {
  display: block;
}

.djs-bendpoint .djs-visual {
  fill: var(--bendpoint-fill-color);
  stroke: var(--bendpoint-stroke-color);
  stroke-width: 1px;
  stroke-opacity: 0.5;
}

.djs-segment-dragger:hover,
.djs-bendpoints.hover .djs-segment-dragger,
.djs-bendpoints.selected .djs-segment-dragger,
.djs-bendpoint:hover,
.djs-bendpoints.hover .djs-bendpoint,
.djs-bendpoints.selected .djs-bendpoint {
  display: block;
}

.djs-drag-active .djs-bendpoints * {
  display: none;
}

.djs-bendpoints:not(.hover) .floating {
  display: none;
}

.djs-segment-dragger:hover .djs-visual,
.djs-segment-dragger.djs-dragging .djs-visual,
.djs-bendpoint:hover .djs-visual,
.djs-bendpoint.floating .djs-visual {
  fill: var(--bendpoint-fill-color);
  stroke: var(--bendpoint-stroke-color);
  stroke-opacity: 1;
}

.djs-bendpoint.floating .djs-hit {
  pointer-events: none;
}

.djs-segment-dragger .djs-hit,
.djs-bendpoint .djs-hit {
  fill: none;
  pointer-events: all;
}

.djs-segment-dragger.horizontal .djs-hit {
  cursor: ns-resize;
}

.djs-segment-dragger.vertical .djs-hit {
  cursor: ew-resize;
}

.djs-segment-dragger.djs-dragging .djs-hit {
  pointer-events: none;
}

.djs-updating,
.djs-updating > * {
  pointer-events: none !important;
}

.djs-updating .djs-context-pad,
.djs-updating .djs-outline,
.djs-updating .djs-bendpoint,
.connect-ok .djs-bendpoint,
.connect-not-ok .djs-bendpoint,
.drop-ok .djs-bendpoint,
.drop-not-ok .djs-bendpoint {
  display: none !important;
}

.djs-segment-dragger.djs-dragging,
.djs-bendpoint.djs-dragging {
  display: block;
  opacity: 1;
}

/**
 * tooltips
 */
.djs-tooltip-error {
  width: 160px;
  padding: 6px;

  background: var(--tooltip-error-background-color);
  border: solid 1px var(--tooltip-error-border-color);
  border-radius: 2px;
  color: var(--tooltip-error-color);
  font-size: 12px;
  line-height: 16px;

  opacity: 0.75;
}

.djs-tooltip-error:hover {
  opacity: 1;
}

/**
 * search pad
 */
.djs-search-container {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  width: 25%;
  min-width: 300px;
  max-width: 400px;
  z-index: 10;

  font-size: 1.05em;
  opacity: 0.9;
  background: var(--search-container-background-color);
  border: solid 1px var(--search-container-border-color);
  border-radius: 2px;
  box-shadow: 0 0 0 2px var(--search-container-box-shadow-color),
    0 0 0 1px var(--search-container-box-shadow-inset-color) inset;
}

.djs-search-container:not(.open) {
  display: none;
}

.djs-search-input input {
  font-size: 1.05em;
  width: 100%;
  padding: 6px 10px;
  border: 1px solid var(--search-input-border-color);
}

.djs-search-input input:focus {
  outline: none;
  border-color: var(--search-input-border-color);
}

.djs-search-results {
  position: relative;
  overflow-y: auto;
  max-height: 200px;
}

.djs-search-results:hover {
  cursor: pointer;
}

.djs-search-result {
  width: 100%;
  padding: 6px 10px;
  background: white;
  border-bottom: solid 1px var(--search-result-border-color);
  border-radius: 1px;
}

.djs-search-highlight {
  color: var(--search-result-highlight-color);
}

.djs-search-result-primary {
  margin: 0 0 10px;
}

.djs-search-result-secondary {
  font-family: monospace;
  margin: 0;
}

.djs-search-result:hover {
  background: var(--search-result-selected-color);
}

.djs-search-result-selected {
  background: var(--search-result-selected-color);
}

.djs-search-result-selected:hover {
  background: var(--search-result-selected-color);
}

.djs-search-overlay {
  background: var(--search-result-selected-color);
}

/**
 * hidden styles
 */
.djs-shape-hidden,
.djs-shape-hidden .djs-hit,
.djs-shape-hidden .djs-outline,
.djs-label-hidden .djs-label {
  display: none !important;
}

.countTask {
  font-size: 12px;
  border-width: 1px;
  border-style: solid;
  border-radius: 100%;
  padding: 5px 7px;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px;
  background-color: #70b8db;
  border-color: #143d52;
  color: #143d52;
}

.form-indexacao-container {
  max-height: calc(100% - 25px);
  padding-top: 8px;
  overflow: auto;
  overflow-x: hidden;
}

.form-indexacao-container .separador {
  padding: 8px;
  background: #333;
  color: #fff;
  margin-bottom: 8px;
}

.thumbnail-container {
  position: relative;
}

.thumbnail-container .thumbnail-hover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: background-color 200ms;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail-container .thumbnail-hover svg {
  display: none;
  transition: display 200ms;
}

.thumbnail-container .thumbnail-hover:hover {
  background-color: #00000045;
}

.thumbnail-container .thumbnail-hover:hover svg {
  display: initial;
}

.thumbnail-container img {
  width: 118px;
  height: 168px;
  cursor: pointer;
}

.thumbnail-container .preview-image {
  max-width: 100%;
  max-height: 100%;
}
.classificacao-imagens {
  max-height: 100%;
  overflow: auto;
}

.classificacao-imagens .drag-imagens {
  border: 1px dashed rgb(126, 122, 122);
  padding: 8px;
}

.classificacao-imagens .drag-imagens p {
  text-align: center;
}

.classificacao-imagens .item-classificacao {
  padding: 8px 0px;
  display: flex;
  align-items: center;
}

.classificacao-imagens .item-classificacao .item-detalhes {
  padding: 0px 8px;
  width: calc(100% - 163px);
}

.classificacao-imagens .item-classificacao .item-detalhes .mark-all {
  font-weight: bold;
}

.classificacao-imagens .item-classificacao .item-detalhes p {
  margin-bottom: 4px;
}
.indexacao-imagens-container .painel .painel-content .painel-body {
  height: calc(100vh - 138px);
  max-height: calc(100vh - 138px);
}
.menuDashboard {
  width: 50px;
  float: right;
}

.view__pass {
  font-size: 16px;
  border-radius: 10px;
  position: relative;
  top: -25px;
  display: inline-block;
  left: 430px;
  cursor: pointer;
}

.painel-body-grahps,
.painel-body-progress {
  min-height: 300px;
}

.painel-body-progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

.main-wrapper {
  background: var(--light-gb);
  height: 100vh;
}

.header-buttons {
  width: 400px;
  text-align: right;
}

.header-buttons a {
  margin-left: 10px;
}

.hidden {
  visibility: hidden;
}

.btnDownloadReport {
  background: #444444 !important;
  color: #fff !important;
  border-radius: 10px !important;
  padding: 10px !important;
}


.main-table-wrapper {
    position: absolute;
    width: 1354px;
    max-height: 60vh;
    left: 400px;
    top: 20%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

.table-wrapper {
    width: 95.5%;
    overflow: hidden;
    position: relative;
    left: 2%;
    
}

.table-wrapper thead th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    color: #4F4F4F;
    font-size: 16px;
    text-align: center;
    border-top: 2px solid #E5E5E5;;
    letter-spacing: 0.03em;
}

.table-wrapper tbody td {
    text-align: center;
    border: 1px solid #E5E5E5;
    border-left: none;
    font-size: 14px;
    letter-spacing: 0.03em;
}

.table-wrapper tr:hover {
    background-color: #f7f7f7;
}

/* tr:nth-child(even) {
    background-color: #f7f7f7;
} */

.main-table-wrapper .search-icon {
    position: absolute;
    left: 50px;
    top: 18px;
    height: 20px;
    width: 20px;
}

.main-table-wrapper .search-input {
    position: relative;
    font-size: 16px;
    left: 90px;
    top: 15px;
    margin-bottom: 25px;
    width: 350px;
    border: none;
    letter-spacing: 0.03em;
    color: #4F4F4F;
}

.main-table-wrapper .search-input::placeholder {
    color: #E5E5E5;
}
.linkicon{
    color:black;

}
.linkicon:hover{
    color:black
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.createtpimg {
  
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}



* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.editimg {
  
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}


.lista {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.lista li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #d8d8d8;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lista li:hover {
  background: #add3ff;
}

.lista li span {
  cursor: pointer;
}

.lista li:last-child {
  border: none;
}



* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.edittpimg {
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

/*
button {
    background-color: #444444;  // Green 
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 25px;
}
*/

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

.selectImage input[type="file"] {
  display: none;
}

.selectImage button {
  width: 100%;
  padding: 60px 0;
  min-height: 500px;
  border: none;
  background: #fff;
  border: 1px solid #77a2ff;
  color: #999;
  cursor: pointer;
}

.viewer {
  position: relative;
  overflow: auto;
}

.viewer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.viewer::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.viewer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(68, 152, 247);
}

.painel .painel-title a {
  background: #6259ca;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
}


* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.edittpimg {
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

.MuiTimelineItem-alignAlternate:nth-child(even) .MuiTimelineItem-content #natureza #buttonToHideLeft{
    display: none;
}
.MuiTimelineItem-alignAlternate:nth-child(even) .MuiTimelineItem-content #natureza #buttonToHideRight{
    display: inline-flex!important;
}


* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.edittpimg {
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

.loading-edit-tpdoc {
    display: flex;
    justify-content: center;
    margin-top: 10%;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.edittpimg {
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}

.loading-edit-tpdoc {
    display: flex;
    justify-content: center;
    margin-top: 10%;
}
.lista {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.lista li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #d8d8d8;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lista li:hover {
  background: #add3ff;
}

.lista li span {
  cursor: pointer;
}

.lista li:last-child {
  border: none;
}

.list-wrapper {
  position: absolute;
  top: 100px;
  width: 650px;
  height: 200px;
}


.section-list-items li {
  position: relative;
  width: 660px;
  height: 18px;
  padding-top: 25px;
  padding-bottom: 25px;

  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  font-weight: bold;
  color: #4F4F4F;
}

.section-list-items .line-divisor {
  background: #D8D8D8;
  height: 2px;
  top: 15px;
}

.section-list-items li.active {
  padding-left: 22px;
  margin-bottom: 10px;
  width: 650px;
  right: 20px;
  height: 10px;
  background: rgba(196, 196, 196, 0.1);
  background-position-y: 5%;
  border-radius: 8px;
  color: #2e8cfa;
  transition: 0.3s;
}

.lista {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.lista li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #d8d8d8;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lista li:hover {
  background: #add3ff;
}

.lista li span {
  cursor: pointer;
}

.lista li:last-child {
  border: none;
}

.list-wrapper {
  position: absolute;
  top: 100px;
  width: 650px;
  height: 200px;
}


.section-list-items li {
  position: relative;
  width: 660px;
  height: 18px;
  padding-top: 25px;
  padding-bottom: 25px;

  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  font-weight: bold;
  color: #4F4F4F;
}

.section-list-items .line-divisor {
  background: #D8D8D8;
  height: 2px;
  top: 15px;
}

.section-list-items li.active {
  padding-left: 22px;
  margin-bottom: 10px;
  width: 650px;
  right: 20px;
  height: 10px;
  background: rgba(196, 196, 196, 0.1);
  background-position-y: 5%;
  border-radius: 8px;
  color: #2e8cfa;
  transition: 0.3s;
}

#buttonPlusStatus {
  margin-top: 28px;
  background: green;
  border-color: green;
}

.listaStatus {

  border-bottom: 1px solid #d8d8d8;
  padding-left: 5px;
  padding-top: 11px;

}

.lista {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.lista li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #d8d8d8;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lista li:hover {
  background: #add3ff;
}

.lista li span {
  cursor: pointer;
}

.lista li:last-child {
  border: none;
}

.list-wrapper {
  position: absolute;
  top: 100px;
  width: 650px;
  height: 200px;
}


.section-list-items li {
  position: relative;
  width: 660px;
  height: 18px;
  padding-top: 25px;
  padding-bottom: 25px;

  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  font-weight: bold;
  color: #4F4F4F;
}

.section-list-items .line-divisor {
  background: #D8D8D8;
  height: 2px;
  top: 15px;
}

.section-list-items li.active {
  padding-left: 22px;
  margin-bottom: 10px;
  width: 650px;
  right: 20px;
  height: 10px;
  background: rgba(196, 196, 196, 0.1);
  background-position-y: 5%;
  border-radius: 8px;
  color: #2e8cfa;
  transition: 0.3s;
}

#buttonPlusStatus {
  margin-top: 28px;
  background: green;
  border-color: green;
}

.listaStatus {

  border-bottom: 1px solid #d8d8d8;
  padding-left: 5px;
  padding-top: 11px;

}

.lista {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.lista li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #d8d8d8;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lista li:hover {
  background: #add3ff;
}

.lista li span {
  cursor: pointer;
}

.lista li:last-child {
  border: none;
}

.list-wrapper {
  position: absolute;
  top: 100px;
  width: 650px;
  height: 200px;
}


.section-list-items li {
  position: relative;
  width: 660px;
  height: 18px;
  padding-top: 25px;
  padding-bottom: 25px;

  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  font-weight: bold;
  color: #4F4F4F;
}

.section-list-items .line-divisor {
  background: #D8D8D8;
  height: 2px;
  top: 15px;
}

.section-list-items li.active {
  padding-left: 22px;
  margin-bottom: 10px;
  width: 650px;
  right: 20px;
  height: 10px;
  background: rgba(196, 196, 196, 0.1);
  background-position-y: 5%;
  border-radius: 8px;
  color: #2e8cfa;
  transition: 0.3s;
}

#buttonPlusStatus {
  margin-top: 28px;
  background: green;
  border-color: green;
}

.listaStatus {

  border-bottom: 1px solid #d8d8d8;
  padding-left: 5px;
  padding-top: 11px;

}


* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.page-form {
    background: var(--light-gb);
    height: 100vh;
}

.editimg {
  
    width: 100px;
    position: absolute;
    top: 18.5%;
    padding: 50px;
    width: auto;

}

.page-form .form-wrapper {
    position: absolute;
    width: 1354px;
    height: 80vh;
    max-height: 60vh;
    left: 400px;
    top: 18.5%;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #E5E5E5;
}


.hide-panel {
  display: none;
}

.createdAt-user {
  display: block;
  font-size: 10px;
}

.badge {
  padding: 4px 6px 5px;
  display: inline-block;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-green {
  background-color: #19b159 !important;
  color: #fff;
}

.badge-red {
  background-color: #f16d75 !important;
  color: #fff;
}

.dados-alterados-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.dados-alterados-content span {
  width: 100%;
  padding: 8px 0;
}

.dados-alterados-content .header {
  color: #444444;
  font-weight: bold;
}

.dados-alterados-content .captalize {
  text-transform: capitalize;
}

.dados-alterados-content .italico {
  font-style: italic;
}

.dados-alterados-content .pl-16 {
  padding-left: 16px;
}

.dados-alterados-content .pl-32 {
  padding-left: 32px;
}

.dados-auditados-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.dados-auditados-content span {
  width: 100%;
  padding: 8px 0;
}

.dados-auditados-content .header {
  color: #444444;
  font-weight: bold;
}

.dados-auditados-content .captalize {
  text-transform: capitalize;
}

.dados-auditados-content .italico {
  font-style: italic;
}

.dados-auditados-content .pl-16 {
  padding-left: 16px;
}

.dados-auditados-content .pl-32 {
  padding-left: 32px;
}

.dados-auditados-cap {
  text-transform: capitalize;
}
.historico-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.select-files-to-upload-content .drag-imagens {
  border: 1px dashed rgb(126, 122, 122);
  padding: 8px;
}

.select-files-to-upload-content .item-upload {
  margin-top: 8px;
  padding: 8px 8px;
  display: grid;
  grid-template-columns: 125px 1fr;
  border: 1px solid #444444;
}

.select-files-to-upload-content .item-upload .item-details {
  display: flex;
  align-items: center;
}

.task-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px 10px 15px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  flex-direction: column;
  border-radius: 11px;
  margin: 15px 0;
  background-color: #fff;
}

.task-list-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.task-list-item__header_title {
  color: #1d212f;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.1px;
  font-size: 13px;
}

.task-list-item__header_subtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
}

.task-list-item__header_subtitle_item {
  margin-right: 22px;
}

.task-list-item__header__label {
  font-weight: 600;
  color: #6259ca;
  margin-right: 5px;
}

.task-list-item__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.task-list-item__content__participants_status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.task-list-item__content__participants_status label {
  padding: 0px 10px 0px 0px;
  font-size: 12px;
  font-weight: 500;
}

.task-list-item__content__participants_status_item {
  flex: 1 1;
}

.task-list-item__content__participants {
  display: flex;
  align-items: center;
}

.task-list-item__content__status {
  text-align: right;
}

.indexadores {
  padding: 15px;
}

.indexadores .accordion {
  border-right: 1px solid rgb(216, 216, 216);
  border-left: 1px solid rgb(216, 216, 216);
  border-bottom: 1px solid rgb(216, 216, 216);
}

.indexadores .accordion .accordion-title {
  border: 1px solid rgb(216, 216, 216);
  background: #0c4383;
  color: #fff;
  min-height: 36px;
}

.indexadores .accordion .accordion-title > div {
  padding: 0;
  margin: 0;
}

.indexadores .accordion .accordion-title span {
  color: #fff;
}

.indexadores h4 {
  margin: 20px 0 10px 0;
  border-bottom: 1px solid #999;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: bolder;
}

.indexadores .indexador strong {
  font-size: 12px;
  color: #6259ca;
}
.separador strong {
  font-size: 12px;
  color: #a8afc7;
  margin-bottom: 1.2rem;
}
.indexadores .indexador span {
  font-size: 12px;
}

.indexadores-actions {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.dados-section {
  width: 100%;
  border: 1px solid #e5e5e5;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.dados-section .header-section {
  margin-bottom: 20px;
}

.dados-section .header-section svg {
  margin-top: -4px;
  margin-right: 5px;
}

.dados-section .indexador {
  margin-bottom: 20px;
}

.noPadding {
  padding: 0;
}

.indexadores-viewer {
  border: 1px solid #e8e8f7;
  border-radius: 11px;
}

.indexadores-section {
  padding: 1.5rem;
}

.indexadores-section-header label {
  color: #1d212f;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.1px;
  margin-bottom: 20px;
  font-size: 13px;
}

.component-editor {
    padding: 10px 0px 30px 0px
}

.text-input{
    padding: 17px 0px 0px 0px;
}

.icon-attached {
    padding: 20px 0px 20px 0px;
}

.white-loading svg {
    color: #ffff;
}
.component-editor {
    padding: 10px 0px 30px 0px
}

.text-input{
    padding: 17px 0px 0px 0px;
}

.icon-attached {
    padding: 20px 0px 20px 0px;
}

.white-loading svg {
    color: #ffff;
}
.component-editor {
    padding: 10px 0px 30px 0px
}

.text-input{
    padding: 17px 0px 0px 0px;
}

.icon-attached {
    padding: 20px 0px 20px 0px;
}

.progress-bar {
    width: 80%;
    display: flex;
    margin-right: 32px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.white-loading svg {
    color: #ffff;
    width: 30%;
}

.dropzone {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    outline: none;
    transition: border .24s ease-in-out;
}

.dropzone p {
    color:#58504F 
}

.dropzone-list{
    padding: 20px 0px 20px 0px;
}

.dropzone-list svg {
    cursor: pointer;
}
.pdf-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
  letter-spacing: 0.1px;
  margin-bottom: 20px;
  font-size: 13px;
}

.left {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 20px;
}

.canvas {
  width: 100%;
  height: 800px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 50px;
}


.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 50%;
}

.paginationButtons button {
  margin-left: 10px;
}

.header-modal {
  border-bottom: 1px solid #dee2e6;
}

.signature {
  position: absolute;
  width: 140px;
  height: 30px;
  background: #6259ca;
  border: 2px solid #6259ca;
  opacity: 0.5;
  font-size: 12px;
  color: #fff;
  padding: 5px;
}

.signature-info {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 1rem;
  border-radius: 0.5rem;
}

.fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.sign-container {
  background-color: #fff;
  margin-bottom: 15px;
}

.sign-container span {
  border-bottom: 1px solid #c3c3c3;
  display: block;
  padding: 5px 15px;
  margin-bottom: 7px;
}

.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 0px; 
  padding: 2px 15px 2px 15px;
}

.indexadorTable {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  font-size: 13px;
  text-align: center;
  padding: 5px;
}
.indexadorTable tr td,
.indexadorTable tr th {
  border: 1px solid #ddd;
}

.indexadorTableNoContent {
  text-align: center;
}


#searchViewer {
  width: 100%;
  height: 100%;
}

#viewer {
  width: 100%;
}

.imageGroup {
  margin-bottom: 20px;
}

.imageGroup b {
  display: block;
  background-color: #444444;
  color: #fff;
  padding: 5px 10px;
}

.thumbViewer {
  display: block;
  margin-top: 10px;
  text-align: center;
  min-height: 300px;
  padding: 10px;
}

.thumbViewer .thumbViewerContainer {
  transition: box-shadow 0.3s;
  background: #e6e6e6;
  padding: 10px 5px 14px 5px;
  height: 100%;
}

.thumbViewer .topThumbViewer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: -7px;
}

.thumbViewer .thumbViewerContainer:hover {
  box-shadow: 8px 6px 11px -5px rgba(0, 0, 0, 0.75);
}

.thumbViewer .bodyThumbViewer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.thumbViewer .bodyThumbViewer .description {
  position: absolute;
  /* transform: rotate(-40deg); */
  color: #fff;
  font-weight: bolder;
  font-size: 12px;
  margin-left: 0px;
  /* text-shadow: 1px 1px 15px #000; */
  box-shadow: 0px -2px 4px #000;
  width: 100%;
  background-color: #6259ca;
  bottom: 8px;
  padding: 3px 0;
}



.thumbViewer img,
.thumbViewer svg,
.thumbViewer .description {
  cursor: pointer;
}

.thumbViewer img,
.thumbViewer svg {
  opacity: 0.8;
  width: 100%;
}

.viewerControlPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.viewerControlPanel button {
  margin-left: 10px;
}

.search-viewer__show-document--navigation {
  padding: 10px !important;
  padding-top: 24px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pg-pesquisa *:focus {
  outline: none;
}

.tabLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.search-viewer__thumbnail-gallery--container {
  min-height: 500px;
  overflow-y: auto;
}

.search-viewer__thumbnail-gallery--wrapper {
  max-height: 600px;
  min-height: 300px;
  padding: 15px 0;
}

.textFound {
  background: #8fa6c6 !important;
}

.search-viewer__file-info {
  margin: 10px;
  font-size: 12px;
  font-weight: bold;
  color: #555;
}

.search-viewer__file-info span {
  color: #999;
}
.controlpanel-item-filter {
    width: 100%;
    align-items: center;
    justify-content: start;
    margin-bottom: 0;
    padding-bottom: 0;

    span {
        margin-left: 20px;
    }
}

.painel-filters-viewr {
    margin: 22px;
}

.row-sm {
    margin-left: 0px;
    margin-right: 0px;
    background-color: transparent;
}

.row-sm>div {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.large-content {
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: '100%' !important;
}
.custom-card-container {
    width: 400px;
    display: flex;
    flex-direction: row;
}
.custom-card {
    cursor: pointer;
    border: 0;
    border-radius: 12px;
    padding: 8px;
    margin-bottom: 20px;
    box-shadow: -7.829px 11.607px 20px 0px rgba(183, 182, 190, 0.178);
}

.custom-card-header {
    background: #ffffff;
    display: flex;
    line-height: 0;
}

.card-sub-title {
    font-size: 13px;
    margin-top: 3px;
}

.title-cards {
    margin-left: 12px !important;
    font-size: 12px !important;
    padding-bottom: 8px;
    color: var(--gray);
}

.tx-12 {
    font-size: 12px;
}

.tx-13 {
    font-size: 13px;
}

.tx-14 {
    font-size: 14px;
}

.d-flex {
    display: flex !important;
}

.mb-2 {
    margin-bottom: 1rem !important;
}

.text-muted {
    color: #8f8fb1 !important;
}

.text-success {
    color: #19b159 !important;
}

.text-danger {
    color: #f16d75 !important;
}

.me-2 {
    margin-left: 0.3rem !important;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
    background-color: transparent !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
}

.main-content-label {
    font-weight: 500;
    color: #3c4858;
    font-size: 14px;
    margin-bottom: 0rem;
    line-height: 1;
}

.card-body {
    flex: 1 1 auto;
    padding: 25px;
}
.select-results {
    width: 320px;
    display: flex;
    align-items: center;
}

.select-results>label {
    margin-right: 8px;
}

.select-results #simple-select {
    width: 120px;
    color: #3c4858;
}
.viewField {
  margin-bottom: 15px;
}
.viewField strong {
  display: block;
  color: #7f7f7f;
}

.comments-header-spacing {
  margin-right: 10px;
}

.task-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px 10px 15px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  flex-direction: column;
  border-radius: 11px;
  margin: 15px 0;
  background-color: #fff;
  cursor: pointer;
}

.task-list-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.task-list-item__header_title {
  color: #1d212f;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.1px;
  font-size: 13px;
}

.task-list-item__header_subtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
}

.task-list-item__header_subtitle_item {
  margin-right: 22px;
  width: 100%;
}

.task-list-item__header__label {
  font-weight: 600;
  color: #6259ca;
  margin-right: 5px;
}

.task-list-item__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.task-list-item__content__participants_status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.task-list-item__content__participants_status label {
  padding: 0px 10px 0px 0px;
  font-size: 12px;
  font-weight: 500;
}

.task-list-item__content__participants_status_item {
  flex: 1 1;
}

.task-list-item__content__participants {
  display: flex;
  align-items: center;
}

.task-list-item__content__status {
  text-align: right;
}

.painel-email-content {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mails-header-spacing {
  margin-right: 20px;
}

.viewField {
  margin-bottom: 15px;
}
.viewField strong {
  display: block;
  color: #7f7f7f;
}

.assitant-container {
}

.messages {
  display: flex;
  flex-direction: column;
  width: 100%; /* Ajuste a largura conforme necessário */
  overflow: auto;
  height: 490px;
  padding: 15px;
  align-items: center;
}

.message {
  width: 50%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 7px;
}

.message .message-title {
  margin-bottom: 20px;
  font-size: 10px;
  font-weight: bolder;
}

.m-assistant {
  align-self: flex-end;
  border: 1px solid #6259ca;
  background-color: #6259ca;
  color: #fff;
}

.m-user {
  align-self: flex-start;
  border: 1px solid #6259ca;
  background-color: #fff;
  color: #6259ca;
}

.form-message {
  padding: 15px;
}

.form-message form {
  display: flex;
  width: 100%;
}

.form-message form input {
  flex: 0 0 90%;
  margin-right: 2%;
}

.form-message form button {
  flex: 0 0 8%;
  background-color: #6259ca;
  color: #fff;
}

.form-message form button.disabled {
  flex: 0 0 8%;
  background-color: #999;
  color: #fff;
}

.menu-search {
  background: #fff;
  border-radius: 11px;
}

.menu-search-header {
  border-bottom: 1px solid #eaedf7;
  padding: 20px 25px 7px 25px;
}

.menu-search h4 {
  font-size: 15px;
  line-height: 1.2;
  color: #1d212f;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.2px;
}

.menu-search-content {
  padding: 25px 10px;
}

.menu-search .nav-link {
  color: #606c8c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 38px;
  margin-bottom: 0.5rem;
}

.menu-search .nav-link.active,
.menu-search .nav-link:hover {
  color: #6259ca;
  font-weight: 600;
}

.menu-search .nav-link svg {
  color: #606c8c;
  font-size: 18px;
  line-height: 0;
  width: 24px;
  margin-right: 10px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  line-height: 0.9;
}

.menu-search-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.menu-search-list li {
  padding: 0;
  margin: 0;
  list-style: none;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

.selectImage input[type="file"] {
  display: none;
}

.selectImage button {
  width: 100%;
  padding: 60px 0;
  min-height: 500px;
  border: none;
  background: #fff;
  border: 1px solid #77a2ff;
  color: #999;
  cursor: pointer;
}

.tpImagemDesc {
  border-bottom: 0.5px solid #d8d8d8;
  width: 100%;
  padding: 10px 0 5px;
}

.tpImagemDesc label {
  font-weight: bolder;
}

.tpImagemDesc span {
  margin-left: 10px;
}

.viewer {
  position: relative;
  overflow: auto;
  text-align: center;
}

.btOtherDocument {
  margin: 20px 0;
}

.viewer .pdfViewerButtons nav {
  background: #dbdbdb;
  padding: 7px 0;
  height: auto;
  overflow: hidden;
}

.viewer .pdfViewerButtons nav button {
  background-color: #444444;
  border: none;
  color: white;
  padding: 5px 10px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 25px;
  margin-left: 20px;
}

.viewer .pdfViewerButtons nav .disabled {
  background-color: #666 !important;
}

.viewer .pdfTotalPages {
  background: #dbdbdb;
  padding: 7px 0;
  height: auto;
  overflow: hidden;
}

.viewer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.viewer::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.viewer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(68, 152, 247);
}

.search-table thead tr th,
.search-table tbody tr td {
  font-size: 12px !important;
}

.search-table tbody tr {
  cursor: pointer;
}

.indexador {
  margin-bottom: 10px;
}

.indexador strong {
  width: 100%;
  display: block;
  color: #444444;
}

.panel-title-blue {
  background: #444444 !important;
  color: #fff !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.field {
  margin-top: 15px;
}

.MuiTextField-root {
  width: 100%;
}

.docTypeFields {
  margin-top: 20px;
}

.docTypeFields .separator {
  padding: 8px;
  background: #333;
  color: #fff;
}

.docTypeFields .MuiDivider-root {
  margin-bottom: 10px;
}
.search-form-buttons button {
  margin-right: 10px;
  width: 100px;
}

.grupo-title-autocomplete {
  cursor: pointer;
  padding: 0;
  padding: -10px;
  color: gray;
  padding: 16px 8px;
  font-size: 12px;
}

.grupo-title-autocomplete:hover {
  background: rgba(228, 228, 228, 0.603);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

.selectImage input[type="file"] {
  display: none;
}

.selectImage button {
  width: 100%;
  padding: 60px 0;
  min-height: 500px;
  border: none;
  background: #fff;
  border: 1px solid #77a2ff;
  color: #999;
  cursor: pointer;
}

.tpImagemDesc {
  border-bottom: 0.5px solid #d8d8d8;
  width: 100%;
  padding: 10px 0 5px;
}

.tpImagemDesc label {
  font-weight: bolder;
}

.tpImagemDesc span {
  margin-left: 10px;
}

.viewer {
  position: relative;
  overflow: auto;
  text-align: center;
}

.btOtherDocument {
  margin: 20px 0;
}

.viewer .pdfViewerButtons nav {
  background: #dbdbdb;
  padding: 7px 0;
  height: auto;
  overflow: hidden;
}

.viewer .pdfViewerButtons nav button {
  background-color: #444444;
  border: none;
  color: white;
  padding: 5px 10px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 25px;
  margin-left: 20px;
}

.viewer .pdfViewerButtons nav .disabled {
  background-color: #666 !important;
}

.viewer .pdfTotalPages {
  background: #dbdbdb;
  padding: 7px 0;
  height: auto;
  overflow: hidden;
}

.viewer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.viewer::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.viewer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(68, 152, 247);
}

.search-table thead tr th,
.search-table tbody tr td {
  font-size: 12px !important;
}

.search-table tbody tr {
  cursor: pointer;
}

.indexador {
  margin-bottom: 10px;
}

.indexador strong {
  width: 100%;
  display: block;
  color: #444444;
}

.panel-title-blue {
  background: #444444 !important;
  color: #fff !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.indexadores-form {
  padding-top: 5px;
}

.panel {
  margin-bottom: 20px;
}
.comments-form {
  margin-top: 20px;
}

.index-title {
  background: #7d75e1;
  padding: 15px;
  display: flex;
  justify-content: center;
}

.index-title.formalizacao {
  background: #7d75e1;
  padding: 15px;
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;
}

.index-title button {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 10px;
}

.index-title span {
  color: #fff;
  font-weight: bold;
}

.index-inputs {
  background: #fff;
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
}

.index-inputs .row {
  margin-bottom: 0;
}

.index-inputs .index__content {
  top: 0;
  margin-bottom: 20px;
}

.index-inputs span {
  font-weight: bold;
}

.index-name {
  color: #444444;
  margin-top: 15px;
}

.index-obg {
  color: #f00 !important;
}

.index__input {
  margin-top: 9px;
  /* max-width: 40%; */
  resize: horizontal;
}

.index__content {
  position: relative;
  top: -17px;
}

.item-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-check svg {
  cursor: pointer;
}

.preview-docs-title {
  background: #fff;
  padding: 0;
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #d8d8d8;
}

.preview-docs-total {
  font-size: 10px;
}

.preview-docs-total strong {
  color: #444444 !important;
}

.author-comment {
  margin-top: 10px;
}

.comment-full-line {
  margin-top: -20px;
}

@media (max-width: 600px) {
  .preview-docs-title {
    margin-top: 10px;
  }

  .preview-doc-image {
    max-width: 420px;
  }
}

.preview-docs-title span {
  color: rgb(49, 49, 49);
  font-weight: bold;
}

.preview-doc {
  min-height: 250px;
  border: 0.5px solid #d8d8d8;
  border-top: none;
  background: #fff;
}

.preview-doc-image {
  max-width: calc(100% - 100px);
}

.checklist-inputs {
  background: #fff;
}

.checklist-inputs span {
  font-weight: bold;
}

.checklist-tasks {
  margin-top: 10px;
  color: #444444;
  font-weight: bold;
  padding: 0px 8px;
}

.checklist-tasks .row {
  margin-bottom: 20px;
}

.checklist-comment {
  background: #fff;
  padding: 30px 0px;
  margin-top: 2px;
  border-radius: 0px 0px 10px 10px;
}

.checklist-commnet-container {
  padding: 0 30px;
}

.checklist-comment button {
  font-weight: bold;
  /* color: rgb(49, 49, 49); */
}

.checklist-comment-area textarea {
  resize: none;
  height: 80px;
  background: rgba(229, 229, 229, 0.1);
}

.checklist-attach-icon {
  position: absolute;
  left: 48%;
  margin-top: -20px;
  z-index: 1;
}

.checklist-attach-icon:hover {
  cursor: pointer;
}

.historico-title {
  position: absolute;
  background: #fff;
  padding: 15px;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-top: -51.5px;
  right: 0;
  margin-right: 15px;
  width: 50%;
  cursor: pointer;
}

.historico-title-active {
  background: #444444;
  color: #fff;
  position: absolute;
  padding: 15px;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-top: -51.5px;
  right: 0;
  margin-right: 15px;
  width: 50%;
  cursor: pointer;
}

.historico-title span {
  font-weight: bold;
}

.historico-title-active span {
  font-weight: bold;
}

.historico-infos {
  background: #fff;
  min-height: 150px;
  padding: 30px;
  position: relative;
  border: 0.5px solid #d8d8d8;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 0px;
}

.historico-infos small {
  margin-left: 40px;
}

.last-comments {
  background: #fff;
  max-height: -moz-fit-content;
  max-height: fit-content;
  padding: 30px 0;
  position: relative;
}

.last-comments small {
  margin-top: 10px;
  margin-left: 40px;
}

.name-commentor {
  color: #444444;
  font-weight: bold;
  margin-left: 10px;
}

.comment-line {
  background: rgba(29, 133, 255, 0.19);
  padding: 5px;
  margin-left: 40px;
  margin-top: 10px;
  width: 90%;
  border-radius: 5px;
}

.comment-full {
  margin-left: 10px;
}

.check-combo {
  margin-top: 10px;
  margin-left: 15px;
  width: 40%;
}

.checklist-buttons button {
  margin-right: 20px;
}

.checklist-buttons a:hover {
  text-decoration: none;
}

.MuiButton-label {
  padding: 4px 2px !important;
}

.Mui-disabled > .MuiButton-label {
  color: #000;
  padding: 4px 2px !important;
}

.MuiInputBase-root > .Mui-disabled {
  color: rgba(0, 0, 0, 0.78);
}

.Mui-disabled > .MuiButton-label {
  color: #000;
  padding: 4px 2px !important;
}
.index-inputs .row {
  margin-bottom: 10px;
}

.filas-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filas-container button {
  margin-left: 10px;
}

.bpmviewer {
  max-height: 450px;
  width: 100%;
  padding: 20px 0;
  margin-top: -50px
}

.xml-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  z-index: 1000;
}
.component-editor {
    padding: 10px 0px 30px 0px
}

.text-input {
    padding: 17px 0px 0px 0px;
}

.icon-attached {
    padding: 20px 0px 20px 0px;
}

.white-loading svg {
    color: #ffff;
}

.esteira-avancar-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
}

.esteira-avancar-table th {
    background-color: #6259CA;
    border: 1px solid #ddd;
    color: #fff
}

.esteira-avancar-table td,
.esteira-avancar-table th {
    padding: 10px
}
.esteiraSearchResult {
  text-align: center;
}

.esteira-buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.button-esteira {
  background-color: #0c4383 !important;
  padding: 8px 10px 8px 10px !important;
  min-width: 32px !important;
}

.field {
  margin-top: 15px;
}

.MuiTextField-root {
  width: 100%;
}

.docTypeFields {
  margin-top: 20px;
}

.docTypeFields .separator {
  padding: 8px;
  background: #333;
  color: #fff;
}

.docTypeFields .MuiDivider-root {
  margin-bottom: 10px;
}

.esteira-indexador {
  margin-bottom: 20px;
}

.search-form-buttons button {
  margin-right: 10px;
  width: 100px;
}

.grupo-title-autocomplete {
  cursor: pointer;
  padding: 0;
  padding: -10px;
  color: gray;
  padding: 16px 8px;
  font-size: 12px;
}

.grupo-title-autocomplete:hover {
  background: rgba(228, 228, 228, 0.603);
}

.titleComp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.titleComp .processo {
  background: #0d4383;
  width: 50%;
  padding: 15px 20px;
  color: #fff;
}
.titleComp .fila {
  background: #7f7f7f;
  width: 50%;
  padding: 15px 20px;
  color: #fff;
}

.titleComp .processo strong,
.titleComp .fila strong {
  margin-right: 7px;
}

.list-wrapper {
    position: absolute;
    top: 100px;
    width: 650px;
    height: 200px;
}


.section-list-items li {
    position: relative;
    width: 660px;
    height: 18px;
    padding-top: 25px;
    padding-bottom: 25px;

    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    font-weight: bold;
    color: #4F4F4F;
}

.section-list-items .line-divisor {
    background: #D8D8D8;
    height: 2px;
    top: 15px;
}

.section-list-items li.active {
    padding-left: 22px;
    margin-bottom: 10px;
    width: 650px;
    right: 20px;
    height: 10px;
    background: rgba(196, 196, 196, 0.1);
    background-position-y: 5%;
    border-radius: 8px;
    color: #2e8cfa;
    transition: 0.3s;
}
.action-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ced4da;
}

.action-item:last-child {
  border: none;
}

.action-item button {
  padding: 8px 15px !important;
}

.validade__senha {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.auditoria-container .row {
    padding: 8px 0px;
}
.ops-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 100px;
}
.ops-container div {
  width: 100%;
  text-align: center;
}

.ops-container div a {
  margin-top: 20px;
  margin-right: 0 !important;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.main-wrapper {
    background: var(--light-gb);
    height: 100vh;
}
.login-form {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;
  width: 100%;
}
.login-form .left,
.login-form .center,
.login-form .right {
  width: 33%;
  min-height: 30%;
}

.login-form .left {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.login-form .center {
  background: #999;
  color: #fff;
  height: 70%;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form .right {
  display: flex;
  align-items: flex-start;
  height: 100%;
}

.login-form .right .content,
.login-form .left .content {
  background: #6aaefc;
  height: 30%;
}

.login-form .left .content {
  border-top-left-radius: 100%;
  text-align: center;
}

.login-form .center .content .loginLogo {
  text-align: center;
}

.login-form .right .content {
  border-bottom-right-radius: 100%;
}

.login-form .loginfields {
  width: 60%;
  margin: 0 auto;
}

.login-form .loginfields input {
  width: 100%;
  border: none;
  padding: 10px;
  margin-top: 20px;
  padding-left: 20px;
  color: #000;
}

.login-form .loginfields button {
  width: 100%;
  background: #fff;
  color: #444444;
  border: none;
  padding: 10px 0;
  margin-top: 20px;
  border-radius: 10px;
}

.login-form .formlinks {
  margin-top: 30px;
}

.login-form .formlinks a {
  display: block;
  color: #fff;
  text-decoration: none;
  margin-bottom: 20px;
  width: 100%;
}

.login-form .formlinks a span {
  font-weight: bolder;
}

.login-form .formlinks a:first-child {
  text-align: right;
}



.login-form .password .color-validation {
  border-color: #f44336;
  background-color: #ffcdd2;
}

.loginError {
  width: 100%;
  padding: 15px;
  text-align: center;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  margin-top: 12px;
}

@media (max-width: 800px) {
  .login-form .center {
    width: 100%;
  }
  .login-form .right,
  .login-form .left {
    display: none;
  }
}

.recaptcha {
  margin-top: 15px;
}

.error__captcha {
  color: #fff;
  background: rgb(252, 8, 8);
  padding: 10px;
  border-radius: 30px;
}

.forgot__pass {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.forgot-password-spacing {
  padding: 30px 0px 0px 0px;
  font-size: 25px;
}

.mensagem-container .mensagem-header {
  display: flex;
  align-items: center;
  padding: 8px;
}

.mensagem-container .mensagem-header h6 {
  font-weight: bold;
}

.mensagem-container .mensagem-body {
  padding: 24px;
}

.mensagem-container .mensagem-body .mensagem-body-header {
  display: flex;
  justify-content: space-between;
}

.mensagem-container
  .mensagem-body
  .mensagem-body-header
  .mensagens-numero-processo {
  background-color: #80808026;
  color: #7f7f7f;
  padding: 0px 8px;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}

.mensagem-container .mensagem-body .mensagem-body-header .mensagem-avatar {
  display: flex;
  align-items: center;
}

.mensagem-container .mensagem-body .mensagem-body-header .mensagem-avatar div {
  margin-right: 8px;
  background-color: #333;
}

.mensagem-container .mensagem-body > p {
  margin: 24px 0px;
}

.mensagem-container .mensagem-body .mensagem-anexos {
  font-weight: bold;
  display: flex;
}

.mensagem-container .mensagem-body .mensagem-anexos .mensagem-anexo {
  padding: 4px 16px;
  background-color: #c7c7c745;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  border-radius: 2px;
  margin-right: 8px;
  cursor: pointer;
}

.mensagem-container .mensagem-body .mensagem-anexos .mensagem-anexo > div {
  margin-right: 8px;
  color: #fff;
  padding: 0px 4px;
  border-radius: 4px;
}

.mensagem-container
  .mensagem-body
  .mensagem-anexos
  .mensagem-anexo
  .mensagem-anexo-pdf {
  background-color: red;
}

.mensagem-container
  .mensagem-body
  .mensagem-anexos
  .mensagem-anexo
  .mensagem-anexo-png {
  background-color: rgb(9, 156, 9);
}

.mensagem-container
  .mensagem-body
  .mensagem-anexos
  .mensagem-anexo
  .mensagem-anexo-jpeg {
  background-color: rgb(18, 6, 175);
}

.mensagens-container .mensagens-nao-lida {
  background-color: #bbbbbb33;
}

.mensagens-container .mensagens-aviso {
  width: 100%;
  height: calc(100vh - 195px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mensagens-container .mensagens-actions {
  padding-left: 24px;
}

.mensagens-container .mensagens-list {
  height: calc(100vh - 195px);
  overflow: scroll;
}

.mensagens-container .mensagens-list .mensagens-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 50px 1fr;
}

.mensagens-container .mensagens-list .mensagens-item .mensagens-item-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
}

.mensagens-container
  .mensagens-list
  .mensagens-item
  .mensagens-item-header
  .mensagens-item-header-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mensagens-container
  .mensagens-list
  .mensagens-item
  .mensagens-item-header
  .mensagens-item-header-details
  svg {
  margin-right: 8px;
}

.mensagens-container .mensagens-list .mensagens-item .mensagens-item-header h6 {
  font-weight: bold;
}

.mensagens-container
  .mensagens-list
  .mensagens-item
  .mensagens-item-header
  .mensagens-item-avatar {
  display: flex;
  align-items: center;
}

.mensagens-container
  .mensagens-list
  .mensagens-item
  .mensagens-item-header
  .mensagens-item-avatar
  div {
  margin-right: 8px;
  background-color: #333;
}

.mensagens-container .mensagens-list .mensagens-item .mensagens-item-body {
  display: flex;
  flex-direction: column;
  padding: 0px 16px 8px 16px;
  width: 100%;
}

.mensagens-container
  .mensagens-list
  .mensagens-item
  .mensagens-item-body
  .mensagens-titulo-nao-lido {
  font-weight: bold;
  color: #7f7f7f;
}

.mensagens-container .mensagens-list .mensagens-item .mensagens-item-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.mensagens-container
  .mensagens-list
  .mensagens-item
  .mensagens-item-footer
  .mensagens-item-numero-processo {
  background-color: #80808026;
  color: #7f7f7f;
  padding: 0px 8px;
}

@media (max-width: 800px) {
  .mensagens-container .mensagens-list {
    height: calc(100vh - 350px);
    overflow: scroll;
  }
}

.tarefa-container .tarefa-header {
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
}

.tarefa-container .tarefa-header .tarefa-aprovar-reprovar {
  display: flex;
  align-items: center;
}

.tarefa-container .tarefa-header .tarefa-title {
  display: flex;
  align-items: center;
}

.tarefa-container .tarefa-header .tarefa-title h6 {
  font-weight: bold;
}

.tarefa-container .tarefa-body {
  padding: 24px;
}

.tarefa-container .tarefa-body .tarefa-body-header {
  display: flex;
  justify-content: space-between;
}

.tarefa-container .tarefa-body .tarefa-body-header .mensagens-numero-processo {
  background-color: #80808026;
  color: #7f7f7f;
  padding: 0px 8px;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}

.tarefa-container .tarefa-body .tarefa-body-header .tarefa-avatar {
  display: flex;
  align-items: center;
}

.tarefa-container .tarefa-body .tarefa-body-header .tarefa-avatar div {
  margin-right: 8px;
  background-color: #333;
}

.tarefa-container .tarefa-body > p {
  margin: 24px 0px;
}

.tarefa-container .tarefa-body .tarefa-anexos {
  font-weight: bold;
  display: flex;
}

.tarefa-container .tarefa-body .tarefa-anexos .tarefa-anexo {
  padding: 4px 16px;
  background-color: #c7c7c745;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  border-radius: 2px;
  margin-right: 8px;
  cursor: pointer;
}

.tarefa-container .tarefa-body .tarefa-anexos .tarefa-anexo > div {
  margin-right: 8px;
  color: #fff;
  padding: 0px 4px;
  border-radius: 4px;
}

.tarefa-container .tarefa-body .tarefa-anexos .tarefa-anexo .tarefa-anexo-pdf {
  background-color: red;
}

.tarefa-container .tarefa-body .tarefa-anexos .tarefa-anexo .tarefa-anexo-png {
  background-color: rgb(9, 156, 9);
}

.tarefa-container .tarefa-body .tarefa-anexos .tarefa-anexo .tarefa-anexo-jpeg {
  background-color: rgb(18, 6, 175);
}

.tarefas-container .tarefas-nao-lida {
  background-color: #bbbbbb33;
}

.tarefas-container .tarefas-aviso {
  width: 100%;
  height: calc(100vh - 195px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tarefas-container .tarefas-actions {
  padding-left: 24px;
}

.tarefas-container .tarefas-list {
  height: calc(100vh - 195px);
  overflow: scroll;
}

.tarefas-container .tarefas-list .tarefas-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 50px 1fr;
}

.tarefas-container .tarefas-list .tarefas-item .tarefas-item-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
}

.tarefas-container
  .tarefas-list
  .tarefas-item
  .tarefas-item-header
  .tarefas-item-header-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tarefas-container
  .tarefas-list
  .tarefas-item
  .tarefas-item-header
  .tarefas-item-header-details
  svg {
  margin-right: 8px;
}

.tarefas-container .tarefas-list .tarefas-item .tarefas-item-header h6 {
  font-weight: bold;
}

.tarefas-container
  .tarefas-list
  .tarefas-item
  .tarefas-item-header
  .tarefas-item-avatar {
  display: flex;
  align-items: center;
}

.tarefas-container
  .tarefas-list
  .tarefas-item
  .tarefas-item-header
  .tarefas-item-avatar
  div {
  margin-right: 8px;
  background-color: #333;
}

.tarefas-container .tarefas-list .tarefas-item .tarefas-item-body {
  display: flex;
  flex-direction: column;
  padding: 0px 16px 8px 16px;
  width: 100%;
}

.tarefas-container
  .tarefas-list
  .tarefas-item
  .tarefas-item-body
  .tarefas-titulo-nao-lido {
  font-weight: bold;
  color: #7f7f7f;
}

.tarefas-container .tarefas-list .tarefas-item .tarefas-item-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.tarefas-container
  .tarefas-list
  .tarefas-item
  .tarefas-item-footer
  .tarefas-item-numero-processo {
  background-color: #80808026;
  color: #7f7f7f;
  padding: 0px 8px;
}

@media (max-width: 800px) {
  .tarefas-container .tarefas-list {
    height: calc(100vh - 350px);
    overflow: scroll;
  }
}

.mailbox-container {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 16px;
  gap: 16px;
}

.mailbox-container .mailbox-pastas svg {
  margin-right: 8px;
}

.mailbox-container .mailbox-pastas .mailbox-pasta {
  border-radius: 0 20px 20px 0;
  width: calc(100% - 16px);
}

.mailbox-container .mailbox-pastas .Mui-selected {
  background-color: #7f7f7f !important;
  color: #fff;
}

@media (max-width: 800px) {
  .mailbox-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    gap: 16px;
  }
}

.title {
 margin: 20px 0px !important;
 font-weight: bold !important;
 font-size: 1.5rem  !important;
}

.pdf-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
  letter-spacing: 0.1px;
  margin-bottom: 20px;
  font-size: 13px;
}

.left {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 20px;
}

.canvas {
  width: 100%;
  height: 800px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 50px;
}


.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 50%;
}

.paginationButtons button {
  margin-left: 10px;
}

.header-modal {
  border-bottom: 1px solid #dee2e6;
}

.signature {
  position: absolute;
  width: 140px;
  height: 30px;
  background: #6259ca;
  border: 2px solid #6259ca;
  opacity: 0.5;
  font-size: 12px;
  color: #fff;
  padding: 5px;
}

.signature-info {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 1rem;
  border-radius: 0.5rem;
}

.fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.sign-container {
  background-color: #fff;
  margin-bottom: 15px;
}

.sign-container span {
  border-bottom: 1px solid #c3c3c3;
  display: block;
  padding: 5px 15px;
  margin-bottom: 7px;
}

.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 0px; 
  padding: 2px 15px 2px 15px;
}

.title {
 margin: 20px 0px !important;
 font-weight: bold !important;
 font-size: 1.5rem  !important;
}

.table-tipo-documento-container .table-tipo-documento-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.holder {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  background: #333;
}

.preloader {
  position: absolute;
  width: 10%;
  height: 0%;
  padding-bottom: 10%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.preloader div {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
  animation: animatePreloader 1s infinite linear;
  transform-origin: 50% 100%;
}
.preloader div:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  border: 3px solid #fce9eb;
  border-radius: 50%;
  box-sizing: border-box;
}
.preloader div:nth-child(2) {
  width: 60%;
  height: 60%;
  top: 20%;
  animation: animatePreloader 0.5s infinite linear;
}
@keyframes animatePreloader {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(360deg);
  }
}

.merge-process-container {
    background-color: #000000;
    color: #FFF;
    opacity: 0.8;
    margin-bottom: 8px;
    border-radius: 10px 0 0 10px;
}

.merge-process-container .merge-process-content {
    padding: 8px;
    display: flex;
    justify-content: center;
}

.merge-process-container .merge-process-content span {
    margin-left: 8px;
}

.merge-process-container .merge-process-progress {
    position: absolute;
    width: 96%;
    right: 0px;
    bottom: 8px;
}
.mergepdf-container {
    position: fixed;
    right: 0;
    top: 80px;
}

@media(max-width: 840px) {
    .mergepdf-container {
        position: fixed;
        right: 0;
        top: 160px;
    }
}
